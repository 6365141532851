import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class VideoAnalysisService {
    private apiUrl = 'https://web-finder.contextqa.com/case/video/analyse';
    private apiUrl2 = 'https://web-finder.contextqa.com/case/video/review';

    constructor(private http: HttpClient) { }

    analyzeVideo(videoUrl: string): Observable<any> {
        console.log('Analyzing video:', videoUrl);
        return this.http.post(this.apiUrl, { url: videoUrl }).pipe(
            catchError(this.handleError)
        );
    }

    reviewRoot(payload: any): Observable<any> {
        return this.http.post(this.apiUrl2, payload).pipe(
            catchError(this.handleError)
        );
    }


    private handleError(error: any) {
        console.error('API Error:', error);
        if (error.error instanceof ErrorEvent) {
            console.error('Client-side error:', error.error.message);
        } else {
            console.error(`Server-side error: ${error.status} ${error.statusText}`);
            console.error('Error body:', error.error);
        }
        return throwError(() => new Error('Something went wrong; please try again later.'));
    }
}