import { Component, InjectionToken, OnInit } from "@angular/core";
import { TestCaseService } from "../../services/test-case.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AuthenticationGuard } from "../../shared/guards/authentication.guard";
import { NotificationsService, NotificationType } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { TestPlanResultService } from "../../services/test-plan-result.service";
//
import { BaseComponent } from "../../shared/components/base.component";
import { TestCase } from "../../models/test-case.model";
import { ConfirmationModalComponent } from "../../shared/components/webcomponents/confirmation-modal.component";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { TestCaseSummaryComponent } from "../webcomponents/test-case-summary.component";
import { TestCaseCloneFormComponent } from "../webcomponents/test-case-clone-form.component";
import { LinkedEntitiesModalComponent } from "../../shared/components/webcomponents/linked-entities-modal.component";
import { TestSuiteService } from "../../services/test-suite.service";
import { InfiniteScrollableDataSource } from "../../data-sources/infinite-scrollable-data-source";
import { WorkspaceVersionService } from "../../shared/services/workspace-version.service";

import { WorkspaceVersion } from "../../models/workspace-version.model";
import { UserPreferenceService } from "../../services/user-preference.service";
import { UserPreference } from "../../models/user-preference.model";
import { DryRunFormComponent } from "../webcomponents/dry-run-form.component";
import { TestStep } from "../../models/test-step.model";
import { ChromeRecorderService } from "../../services/chrome-recoder.service";
import { ToastrService } from "ngx-toastr";
import { TestStepService } from "../../services/test-step.service";
import { ReportBugComponent } from "../webcomponents/report-bug.component";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { PopupOpenComponent } from "../manually-test-run-popup/popupOpen.component";
import mixpanel from "mixpanel-browser";
import { Socket } from "ngx-socket-io";
import { ExecutionModelComponent } from "../results/execution/execution-model/execution-model.component";
import { Subscription } from "rxjs";
import { UrlConstantsService } from "app/shared/services/url.constants.service";
import { SharedDataService } from "app/shared/services/shared-data";

@Component({
  selector: "app-test-case-details",
  templateUrl: "./test-case-details.component.html",
  styles: [],
})
export class TestCaseDetailsComponent extends BaseComponent implements OnInit {
  private rerunSubscription: Subscription;
  public testCaseId: number;
  public testSteps: any;
  public testCase: TestCase;
  public isSearchEnable: boolean = false;
  public isTestCaseFetchingCompleted: boolean = false;
  public version: WorkspaceVersion;
  private userPreference: UserPreference;
  public selectedStepsList: TestStep[];
  public stepsLength: number;
  public IsExecuteBtn: boolean;
  public testRunId: any;
  private executionDialog!: any;

  public seconds: any = 0;
  public minutes: any = 0;
  public topicId: any;
  public successSteps: any = 0;
  public failSteps: any = 0;

  public stepsData: any = [];

  public ExecutedStepsData: any[] = [];

  public interval: any;
  private lastValidImageUrl: string | null = null;
  cqaOrigin: any;
  isSidePanelOPened: boolean = false;

  constructor(
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    private route: ActivatedRoute,
    private testPlanResultService: TestPlanResultService,
    // private userService: UserService,
    private testCaseService: TestCaseService,
    private testSuiteService: TestSuiteService,
    private versionService: WorkspaceVersionService,
    private matModal: MatDialog,
    private router: Router,
    private userPreferenceService: UserPreferenceService,
    private chromeRecorderService: ChromeRecorderService,
    private testStepService: TestStepService,
    private socket: Socket,
    private dialog: MatDialog,
    private http: HttpClient,
    private URLConstants: UrlConstantsService,
    public sharedDataService: SharedDataService

  ) {
    super(authGuard, notificationsService, translate, toastrService);
  }

  get isGroup() {
    return this.testCase?.isStepGroup ? "Step Group" : "Test Case";
  }

  ngOnInit(): void {
    this.cqaOrigin = window.location.hostname.split('.')[0];
    mixpanel.identify(
      window.location.hostname.split(".")[0] +
      "-" +
      localStorage.getItem("useremail")
    );
    mixpanel.people.set({
      Plan: "Premium",
      origin: window.location.hostname.split(".")[0],
      email: localStorage.getItem("useremail"),
    });
    mixpanel.track("Test Steps List");
    this.route.params.subscribe((params: Params) => {
      this.pushToParent(this.route, params);
      this.testCaseId = params.testCaseId;
      this.fetchUserPreference();
      this.fetchTestCase();
      this.fetchSteps();
    });
    this.testCaseService
      .getStepLengthEmitter()
      .subscribe((hasInspectorFeatureres) => {
        this.stepsLength = hasInspectorFeatureres;
      });
  }

  ngOnDestroy(): void {
    if (this.rerunSubscription) {
      this.rerunSubscription.unsubscribe();
    }
  }

  fetchUserPreference() {
    this.userPreferenceService
      .show()
      .subscribe((res) => (this.userPreference = res));
  }

  hasInspectorFeature() {
    return (
      (this.version && this.version.workspace.isAndroidNative) ||
      (this.version && this.version.workspace.isIosNative)
    );
  }

  fetchTestCase() {
    this.testCaseService.show(this.testCaseId).subscribe(
      (res) => {
        this.fetchVersion(res.workspaceVersionId);
        this.testCase = res;

        // this.fetchUsers();
        this.isTestCaseFetchingCompleted = true;
      },
      (error) => {
        this.isTestCaseFetchingCompleted = true;
      }
    );
  }

  public fetchSteps() {
    let query = "testCaseId:" + this.testCaseId;
    this.testStepService.findAll(query, "position").subscribe((res) => {
      this.testSteps = res;
      // this.testSteps.content = this.testSteps?.content.filter(step => step.type != TestStepType.WHILE_LOOP)
      if (this.testSteps.content.length > 0) {
        this.testSteps.content = [...res.content];
        this.testSteps.content[0].setStepDisplayNumber(this.testSteps.content);
      }
    });
  }

  fetchVersion(versionId) {
    this.versionService.show(versionId).subscribe((res) => {
      this.version = res;
      if (this.version.workspace.isWebMobile) {
        this.chromeRecorderService.isChromeBrowser();
        this.chromeRecorderService.pingRecorder();
        setTimeout(() => {
          if (this.chromeRecorderService.isInstalled) {
            this.chromeRecorderService.recorderVersion = this.version;
            this.chromeRecorderService.recorderTestCase = this.testCase;
          }
        }, 200);
      }
    });
  }

  recordingBtn() {
    if (chrome && chrome.runtime) {
      let data = {
        type: "test_case",
        id: this.testCaseId,
        result: this.testCase,
        action: "isPortalRecordingStart",
        origin: window.location.hostname.split(".")[0],
        jwt: localStorage.getItem("_t"),
        userEmail: window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail')
      };
      chrome.runtime.sendMessage(
        "pgadpooodaipbhbchnojijmlkhpamadh",
        { message: "isPortalRecordingStart", data: data },
        (data: any) => {
          if (data) {
            console.log(data);
          } else {
          }
        }
      );
    }
  }

  stopExecution() {
    if (chrome && chrome.runtime) {
      let data = {
        type: "test_case",
        id: this.testCaseId,
        action: "EXECUTION_END",
        origin: window.location.hostname.split(".")[0],
        jwt: JSON.stringify(localStorage.getItem("_t")),
      };
      chrome.runtime.sendMessage(
        "pgadpooodaipbhbchnojijmlkhpamadh",
        { message: "EXECUTION_END", data: data },
        (data: any) => {
          // console.log(data,"Extension is installed.");
          if (data) {
            console.log(data);
          } else {
          }
        }
      );
    }
  }

  deleteTestCase(permanently?) {
    this.translate
      .get("message.common.confirmation.message", { FieldName: this.isGroup })
      .subscribe((res) => {
        const dialogRef = this.matModal.open(ConfirmationModalComponent, {
          width: "450px",
          data: {
            description: this.translate.instant(
              "message.common.confirmation.message",
              { FieldName: this.isGroup }
            ),
            isPermanentDelete: permanently,
            title: "Test Case",
            item: "test case",
            name: this.testCase.name,
            note: this.translate.instant(
              "message.common.confirmation.test_data_des",
              { Item: "test case" }
            ),
            confirmation: permanently
              ? this.translate.instant("message.common.confirmation.note")
              : this.translate.instant(
                "message.common.confirmation.note_trash"
              ),
          },
          panelClass: ["matDialog", "delete-confirm"],
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            if (permanently) this.deletePermanently();
            else this.markAsDeleted();
          }
        });
      });
  }

  markAsDeleted() {
    let fieldName = this.testCase.isStepGroup ? "Step Group" : "Test Case";
    this.testCaseService.markAsDeleted(this.testCaseId).subscribe({
      next: () => {
        this.fetchTestCase();
        this.translate
          .get("message.common.deleted.success", { FieldName: this.isGroup })
          .subscribe((res: string) => {
            this.showNotification(NotificationType.Success, res);
          });
      },
      error: (error) => {
        if (error.status == "400") {
          this.showNotification(NotificationType.Error, error.error);
        } else {
          this.translate
            .get("message.common.deleted.failure", { FieldName: this.isGroup })
            .subscribe((res: string) => {
              this.showNotification(NotificationType.Error, res);
            });
        }
      },
    });
  }

  openDetails() {
    this.matModal.open(TestCaseSummaryComponent, {
      width: "80%",
      data: { testCase: this.testCase },
      panelClass: ["mat-dialog", "rds-none"],
    });
  }

  openTestCaseClone() {
    mixpanel.identify(window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail'));  // a - b
    mixpanel.people.set({ "Plan": "Premium", "origin": window.location.hostname.split('.')[0], "email": localStorage.getItem('useremail') });
    mixpanel.track('Testcase clone form');
    let dialogRef = this.matModal.open(TestCaseCloneFormComponent, {
      width: "450px",
      panelClass: ["mat-dialog", "rds-none"],
      data: {
        testCase: this.testCase,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) this.router.navigate(["/td", "cases", res.id]);
    });
  }

  restore() {
    this.testCaseService.restore(this.testCaseId).subscribe({
      next: () => {
        this.fetchTestCase();
        this.translate
          .get("message.common.restore.success", { FieldName: this.isGroup })
          .subscribe((res: string) => {
            this.showNotification(NotificationType.Success, res);
          });
      },
      error: (error) => {
        if (error.status == "400") {
          this.showNotification(NotificationType.Error, error.error);
        } else {
          this.translate
            .get("message.common.restore.failure", { FieldName: this.isGroup })
            .subscribe((res: string) => {
              this.showAPIError(error, res, this.isGroup);
            });
        }
      },
    });
  }

  deletePermanently() {
    this.testCaseService.destroy(this.testCaseId).subscribe({
      next: () => {
        this.router.navigate([
          "/td",
          this.testCase.workspaceVersionId,
          this.testCase?.testcaseRedirection,
          "filter",
          this.userPreference?.testCaseFilterId,
        ]);
      },

      error: (error) => {
        this.showNotification(
          NotificationType.Error,
          error && error.error && error.error.error
            ? error.error.error
            : this.translate.instant("message.component.delete.failure")
        );
      },
    });
  }

  public fetchLinkedCases() {
    let testCases: InfiniteScrollableDataSource;
    testCases = new InfiniteScrollableDataSource(
      this.testSuiteService,
      ",testcaseId:" + this.testCaseId
    );
    waitTillRequestResponds();
    let _this = this;

    function waitTillRequestResponds() {
      if (testCases.isFetching)
        setTimeout(() => waitTillRequestResponds(), 100);
      else {
        if (testCases.isEmpty) _this.deleteTestCase(true);
        else _this.openLinkedTestCasesDialog(testCases);
      }
    }
  }

  private openLinkedTestCasesDialog(list) {
    this.translate.get("test_case.linked_with_cases").subscribe((res) => {
      this.matModal.open(LinkedEntitiesModalComponent, {
        width: "568px",
        height: "55vh",
        data: {
          description: res,
          linkedEntityList: list,
        },
        panelClass: ["mat-dialog", "rds-none"],
      });
    });
  }

  get canShowRunResult() {
    return this.testCase && !this.testCase.isStepGroup;
  }

  get canShowBulkActions() {
    return this.selectedStepsList && this.selectedStepsList.length > 1;
  }

  openDryRun() {
    // this.isExecuteBtn = true;
    // this.isRunBtn = false;
    // this.testStepService.showexecute(this.testCaseId).subscribe((step) => {
    //     console.log('============================step=============================', step)
    // });

    this.matModal.open(DryRunFormComponent, {
      height: "100vh",
      width: "60%",
      position: { top: "0px", right: "0px" },
      panelClass: ["mat-dialog", "rds-none"],
      data: {
        testCaseId: this.testCase.id,
      },
    });
  }

  reportBug() {
    this.matModal.open(ReportBugComponent, {
      width: "80%",
      height: "100vh",
      position: { top: "0", right: "0", bottom: "0" },
      data: { isFromTest: true, testCase: this.testCase },
      panelClass: ["mat-overlay"],
    });
  }

  OnRun() {
    this.openModalInstant();
    mixpanel.identify(
      window.location.hostname.split(".")[0] +
      "-" +
      localStorage.getItem("useremail")
    );
    mixpanel.people.set({
      Plan: "Premium",
      origin: window.location.hostname.split(".")[0],
      email: localStorage.getItem("useremail"),
    });
    mixpanel.track("Run TestCase");
    if (!this.IsExecuteBtn) {
      this.IsExecuteBtn = true;
      // this.translate
      //   .get("execution.initiate.success")
      //   .subscribe((message: string) => {
      //     this.showNotification(NotificationType.Success, message);
      //   });
      this.testStepService.showexecute(this.testCaseId).subscribe(
        (step) => {
          this.IsExecuteBtn = false;
          this.translate
            .get("execution.result.SUCCESS")
            .subscribe((message: string) => {
              this.showNotification(NotificationType.Success, message);
              this.topicId = step?.topic;
              this.testRunId = step?.data[0].id;
              this.startWebSocket(step?.topic);
              // this.router.navigate(['/td/runs', step?.data[0].id , step?.topic])
            });
        },
        (error: HttpErrorResponse) => {
          this.IsExecuteBtn = false;
          if (
            error.status == 400 &&
            error.error &&
            error.error.error &&
            typeof error.error.error == "string"
          ) {
            this.showAPIError(
              error,
              this.translate.instant(
                error.error.error ||
                this.translate.instant("execution.initiate.failure")
              )
            );
            this.executionDialog.close();
          } else {
            this.showAPIError(
              error,
              this.translate.instant("execution.initiate.failure")
            );
            this.executionDialog.close();
          }
        }
      );
    }
  }
  OnExecuteBtn() {
    this.IsExecuteBtn = false;
    this.OnRun();
  }

  manuallyRun() {
    mixpanel.identify(
      window.location.hostname.split(".")[0] +
      "-" +
      localStorage.getItem("useremail")
    );
    mixpanel.people.set({
      Plan: "Premium",
      origin: window.location.hostname.split(".")[0],
      email: localStorage.getItem("useremail"),
    });
    mixpanel.track("Manully Run TestCase");
    const param = "create_steps=true";
    this.testStepService
      .manualRun(this.testCaseId, param)
      .subscribe((res: any) => {
        if (res) {
          this.router.navigate(["/td/test_case_results", res.testCaseResultId]);
        }
      });
  }

  testPopup() {
    this.matModal.open(PopupOpenComponent, {
      width: "1000px",
      height: "540px",
      data: {},
    });
  }

  /** Open Modal When clock on Run */
  openModalInstant() {
    let config = new MatDialogConfig();
    config = {
      width: "100vw",
      maxWidth: "100%",
      height: "100%",
      panelClass: "full-screen-modal",
      hasBackdrop: false,
    };
    this.executionDialog = this.dialog.open(ExecutionModelComponent, config);
    this.executionDialog.componentInstance.loader = true;
    this.fetchSteps();
    this.logMessages = [];
    this.rerunSubscription = this.testStepService.onRerun.subscribe(() => {
      this.socket.removeListener(this.topicId);
      this.reInitialData();
      this.fetchSteps();
      setTimeout(() => {
        this.OnRun();
      }, 500);
    });
  }

  /** Open Execution Model */
  startWebSocket(topicId: any) {
    let messageLog: any = [];
    let networkLog: any = [];
    this.socket.on(topicId, (data: any) => {
      if (data.data.result && data.data.result.length && data.data.stepStatus == "FAILED") {
        data["type"] = "execution";
        data.data["stepStatus"] = "FAILURE"; // we will remove this near feautre
        this.sharedDataService.setTestCaseResult(data);
      }

      if (data.data.status == "START") {
        this.interval = setInterval(() => {
          this.stopWatch();
          let time =
            (this.minutes < 10 ? "0" + this.minutes : this.minutes) +
            ":" +
            (this.seconds < 10 ? "0" + this.seconds : this.seconds);
          this.executionDialog.componentInstance.time = time;
        }, 1000);
        this.executionDialog.afterClosed().subscribe((res) => {
          this.socket.removeListener(this.topicId);
          this.reInitialData();
          this.fetchSteps();
          if (this.rerunSubscription) {
            this.rerunSubscription.unsubscribe();
          }
        });
      }
      if (data.data?.messageLog) {
        data.data?.messageLog.forEach(item => messageLog.push({ ...item }));
        data.data.messageLog = messageLog;
      } else {
        data.data.messageLog = messageLog
      }
      if (data.data?.log) {
        this.logMessages.push(data.data?.log);
        data.data.log = this.logMessages;
      } else {
        data.data.log = this.logMessages;
      }
      if (data.data?.networkLog) {
        data.data?.networkLog.forEach(item => networkLog.push({ ...item }));
        data.data.networkLog = networkLog;
      } else {
        data.data.networkLog = networkLog
      }
      this.getSocketData(data);
    });
  }

  stopWatch() {
    this.seconds++;
    if (this.seconds / 60 === 1) {
      this.seconds = 0;
      this.minutes++;
    }
  }
  /** Get Socket Data */
  private logMessages: string[] = [];
  getSocketData(data) {
    this.executionDialog.componentInstance.loader = false;
    if (data.data.stepStatus == "SUCCESS" && data.data.status != "START") {
      if (data.data.id) {
        this.successSteps++;
      }
    } else if (data.data.stepStatus == "FAILURE") {
      if (data.data.id) {
        this.failSteps++;
      }
    } else if (data.data.stepStatus == "CONVERSATION" || data.data.stepStatus == "TIMEOUT") {
      this.executionDialog.componentInstance.Conversation = data.data;
    }
    let stepData = this.testSteps.content.find(
      (step) => step.id == data.data.id
    );
    if (stepData) {
      stepData["executionData"] = data.data;
      this.ExecutedStepsData.push(stepData);
    }
    this.stepsData.push(data.data);
    this.testSteps.content = this.testSteps.content.map((step) => {
      if (step.id == data.data.id) {
        return {
          ...step,
          ...{ executionData: data.data },
        };
      } else {
        return step;
      }
    });
    // if(data.data.status != "COMPLETED"){
    if (data.data.imageUrl) {
      this.lastValidImageUrl = data.data.imageUrl;
    }
    data.data.imageUrl = data.data.imageUrl || this.lastValidImageUrl;
    this.executionDialog.componentInstance.Prop = data.data;
    // }
    this.executionDialog.componentInstance.ExecutedStepsData =
      this.ExecutedStepsData;
    this.executionDialog.componentInstance.TestSteps = this.testSteps.content;
    this.executionDialog.componentInstance.failSteps = this.failSteps;
    this.executionDialog.componentInstance.successSteps = this.successSteps;
    this.executionDialog.componentInstance.stepsData = this.stepsData;
    this.executionDialog.componentInstance.TestCase = this.testCase;
    this.executionDialog.componentInstance.testRunId = this.testRunId;
    if (data.data.status == "COMPLETED") {
      this.socket.removeListener(this.topicId);
      this.lastValidImageUrl = null;
      // this.executionDialog.close();
      this.reInitialData();
    }
  }

  reInitialData() {
    this.seconds = 0;
    this.minutes = 0;
    this.failSteps = 0;
    this.successSteps = 0;
    this.stepsData = [];
    clearInterval(this.interval);
    this.ExecutedStepsData = [];
  }

  receiveMessage(obj: any) {
    console.log("obj>>>>>>>>", obj);
    if (obj) {
      const data = {
        testCaseId: this.testCaseId,
        testCase: this.testCase
      }
      this.testCaseService.testCaseData.next(data);
    }
  }

  /** Open SidePanel */
  openSidePanelAndListSteps() {
    if (chrome && chrome.runtime) {
      let data = {
        type: "test_case",
        id: this.testCaseId,
        result: this.testCase,
        action: "openSidePanelFromPortal",
        origin: window.location.hostname.split(".")[0],
        jwt: localStorage.getItem("_t"),
        userEmail: window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail')
      };
      chrome.runtime.sendMessage(
        "pgadpooodaipbhbchnojijmlkhpamadh",
        { message: "openSidePanelFromPortal", data: data },
        (data: any) => {
          if (data) {
            console.log(data);
            this.isSidePanelOPened = true;
          } else {
          }
        }
      );
    }
  }

  /** Report Execution */
  reportsExecution(type: any) {
    const reportEndpoints: any = {
      'performance': '/report/webp/tc/',
      'security': '/report/security/tc/',
      'load': '/report/load/tc/',
      'accessibility': '/report/accessibility/tc/'
    };
    const notificationMessages: any = {
      'performance': 'Performance report fetched successfully.',
      'security': 'Security report fetched successfully.',
      'load': 'Load report fetched successfully.',
      'accessibility': 'Accessibility report fetched successfully.'
    };
    console.log(type)
    const baseURL = this.URLConstants.CQA_LAB_SERVERurl_1;
    const endpoint = reportEndpoints[type];
    const url = `${baseURL}${endpoint}${this.testCaseId}`;
    this.openModalInstant();
    const message = notificationMessages[type] || 'Report fetched successfully.';
    this.showNotification(NotificationType.Success, message);
    this.http.get(url).subscribe({
      next: (res: any) => {
        if (res) {
          this.topicId = res?.topic;
          this.startWebSocket(res?.topic);
          this.showNotification(NotificationType.Success, res.message);
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }


  Validate() {
    this.sharedDataService.isRunningValidate = true;
    this.testStepService
      .valaidateRun(this.testCaseId)
      .subscribe((res: any) => {
        if (res) {
          this.sharedDataService.setTestCaseResult(res);
        }
      });
  }
}
