<div class="h-100 ts-form" [formGroup]="form">
  <div class="theme-overlay-container" *ngIf="!isFullScreenMode">
    <div class="theme-overlay-header">
      <div class="theme-overlay-title">
        <span [translate]="'test.step.details.modal.title'"></span>
        <span [textContent]="'(' + testStep.stepDisplayNumber + ')'"></span>
        <span class="pl-4" [translate]="'test.step.details.modal.title.details'"></span>
      </div>
      <!-- <button
        class="close"
        type="button"
        [matTooltip]="'hint.message.common.close' | translate"
        (click)="maxTimeValidate()"
      ></button> -->
    </div>

    <div [class.full-content-view]="isFullScreenMode" [class.pt-30]="isFullScreenMode" [class.pt-20]="!isFullScreenMode"
      class="theme-overlay-content without-footer">
      <!-- <div class="form-group" *ngIf="!testStep.isRestStep && !testStep.isForLoopStep">
        <div class="p-0 field m-0">
          <input
            type="number"
            id="waitTime"
            name="waitTime"
            placeholder=" "
            (focus)="form?.controls['waitTime'].errors = {}"
            class="form-control"
            [formControlName]="['waitTime']"/>
          <label
            [textContent]="('step_result.max.waitTime' | translate) + ' ( < '+ ('step_result.waitTime' | translate : {waitTime: '120'}) +' )'"
            for="waitTime" class="required"></label>
        </div>
        <div
          *ngIf="form?.get('waitTime')?.hasError('max')"
          class="error" [translate]="'test_step.max_wait_time.error'">
        </div>
      </div>
       -->
      <p style="margin-bottom: 20px">Attributes</p>
      <ng-template [ngIf]="!IsDisplay_custom_code">
        <div formGroupName="event">
          <div class="form-group" *ngFor="let f of templateVariables">
            <div class="p-0 field m-0" *ngIf="f.name !== 'custom_code'">
              <input type="text" placeholder="Provide context to AI" class="form-control" [formControlName]="f.name" />
              <label [textContent]="f.label" for="metadata">
                {{ f.label }}
              </label>
            </div>
            <div class="p-0 field m-0" *ngIf="f.name === 'custom_code'" style="margin-top: 10px">
              <ngs-code-editor
                theme="vs-dark"
                [codeModel]="model"
                language="javascript"
                (valueChanged)="setEditorContents($event)"
                [dependencies]="dependencies"
                [options]="optionsData">
              </ngs-code-editor>
              <label [textContent]="'Code'" for="metadata">
                Code
              </label>

<!--              <input type="text" placeholder="Provide context to AI" class="form-control" [formControlName]="f.name" />-->
<!--              <label [textContent]="f.label" for="metadata">-->
<!--                {{ f.label }}-->
<!--              </label>-->
            </div>

          </div>
        </div>
      </ng-template>




      <div class="form-group">
        <div class="p-0 field m-0">
          <input type="text" id="metadata" name="metadata" placeholder="Provide context to AI" class="form-control"
            [formControlName]="['metadata']" />
          <label [textContent]="'step_result.metadata' | translate" for="metadata"></label>
        </div>
      </div>
      <!-- <div class="form-group">
        <div class="p-0 field m-0">
          <input type="text" id="specs" name="specs" placeholder=" " class="form-control"
            [formControlName]="['specs']" />
          <label [textContent]="'step_result.specs' | translate" for="specs"></label>
        </div>
      </div> -->
      <div class="form-group">
        <div class="p-0 field m-0">
          <input type="text" id="description" name="description" placeholder=" " class="form-control"
            [formControlName]="['description']" />
          <label [textContent]="'step_result.description' | translate" for="description"></label>
        </div>
      </div>

      <div *ngIf="PW_Locator != undefined">
        <p>Other Locators</p>
        <div class="form-group" *ngFor="let pwLocatorList of PW_Locator">
          <div class="p-0 field m-0">
            <div>
              <input type="text" [value]="pwLocatorList" #userinput class="form-control" readonly style="padding-left: 26px;" />
              <div class="copyBtn" style="margin-top: -27px;margin-left: 7px;">
                <img src="http://clipground.com/images/copy-4.png" width="15" (click)="copyInputMessage(userinput)" title="Click to Copy"/>
                <!-- <button (click)="copyInputMessage(userinput)">Copy</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- <div class="form-group">
        <label class="control-label" [translate]="'test_step.results.prerequisite'"></label>
        <mat-form-field class="mat-select-custom w-100" appearance="fill"  [class.disabled]="options.isTestStepResultsView">
          <mat-select [placeholder]="'None'"
                      [disabled]="options.isTestStepResultsView"
                      [class.disabled-pointer-events]="options.isTestStepResultsView"
                      disableOptionCentering panelClass="single"
                      [formControlName]="['preRequisiteStepId']"
                      (selectionChange)="resetOnPreRequisiteNone($event)">
            <mat-option [value]="null" [textContent]="'message.common.none' | translate"></mat-option>
            <mat-option
              *ngFor="let prerequisite of prerequisiteList; let index = index"
              [value]="prerequisite.id"
              [textContent]="'Step ' + (prerequisite.position + 1) + ' - ' + (!!prerequisite.action? prerequisite.action : prerequisite?.stepGroup?.name)"></mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        *ngIf="!testStep.isConditionalType && !conditionalIf"
        class="form-group d-flex align-items-center">
        <mat-checkbox
          [formControl]="priorityControl"
        ><span [translate]="'test_step.priority.MAJOR_STOP'"></span></mat-checkbox>
      </div>
      <div class="form-group d-flex align-items-center">
        <mat-checkbox
          [matTooltip]="testStep?.isConditionalType ? ('test_step.results.ignore_step_result_hint'|translate):''"
          [matTooltipPosition]="'right'"
          [class.disabled]="testStep?.isConditionalType"
          [disabled]="testStep?.isConditionalType"
          [formControlName]="['ignoreStepResult']"
          (change)="changePriorityOverIgnoreResult($event)">
          <span [translate]="'test_step.results.ignore_step_result_label'"></span>
          <i [matTooltip]="'test_step.details.ignore_step_result_hint' | translate"
             class="fa-question-circle-solid ml-8"></i></mat-checkbox>
      </div>
      <div class="form-group">
        <mat-checkbox
          [matTooltip]="canAllowDisableStep ? ('test_step.results.cant_enable_hint'|translate):''"
          [matTooltipPosition]="'right'"
          [class.disabled]="canAllowDisableStep"
          [disabled]="canAllowDisableStep"
          [formControlName]="['disabled']">
          <span [translate]="'test_step.results.disable_label'"></span></mat-checkbox>
      </div>

      <div>
        <mat-checkbox
          [matTooltipPosition]="'right'"
          [class.disabled]="canAllowDisableStep"
          [disabled]="canAllowDisableStep"
          [formControlName]="['visualEnabled']">
          <span [translate]="'test_step.results.visual_enabled_label'"></span></mat-checkbox>
      </div> -->

      <div>
        <button class="theme-btn-clear-default" [translate]="'btn.common.cancel'" type="button" (click)="CloseBtn()" ></button>

        <button class="theme-btn-primary" [textContent]="'btn.common.create' | translate" type="button" (click)="maxTimeValidate()"></button>

      </div>
    </div>
  </div>
</div>
