import {Component, OnInit, Output, ViewChild, EventEmitter} from '@angular/core';
import {TestPlanResult} from "../../models/test-plan-result.model";
import {TestPlanResultService} from "../../services/test-plan-result.service";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BaseComponent} from "../../shared/components/base.component";
import * as moment from 'moment';

import { Subscription} from "rxjs";
import {TestPlanType} from "../../enums/execution-type.enum";
import mixpanel from 'mixpanel-browser';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
interface Testcase {
  id: number,
  name: string,
  index:number;
  testCaseId:number;
}


interface TreeNode {
  id: number;
  testCaseId: number;
  testCasesDtoSize: number;
  index:number;
  duration:number;
  name: string;
  result:string;
  isManualExecution:boolean;
  status:string;
  testCasesDto: Testcase[]
  children: TreeNode[];
}


interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'app-runs-details',
  templateUrl: './details.component.html',
  styles: []
})
export class RunsDetailsComponent extends BaseComponent implements OnInit {
  @Output('toggleDetailsAction') toggleDetailsAction = new EventEmitter<Boolean>();

  public testPlanResult: TestPlanResult;
  public originalExecutionResult: TestPlanResult = new TestPlanResult();
  private runId: Number;
  public showList: String = 'TCR';
  public showFilter: Boolean;
  public showRunDetails: Boolean = true;
  public autoRefreshSubscription: Subscription;
  public autoRefreshInterval: number = 60000;
  public isDisabledAutoRefresh: boolean = false;
  public isExecutionRunning: boolean = false;
  public isExecutionFetchingCompleted: boolean = false;

  public drawerOpen : boolean = false;
  selectedCaseId: any;
  selectedResultId:any;
  private _transformer = (node: TreeNode, level: number) => {
    if (node) {
      return {
        expandable: (node.children && node.children.length > 0) || (node.children === null),
        name: node.name,
        duration:node.duration,
        result:node.result,
        isManualExecution : node.isManualExecution,
        level: level,
        index : node.index,
        testCaseId : node.testCaseId,
        id: node.id,
        status: node.status,
        testCasesDtoSize: node.testCasesDtoSize
      };
    } else {
      // Handle the case when node is null or undefined
      return {
        expandable: false,
        name: null,
        index : 0,
        level: level,
        id: null
      };
    }
  };
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    
  constructor(
    private route: ActivatedRoute,
    private testPlanResultService: TestPlanResultService) {
    super();
  }
  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      mixpanel.identify(window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail'));
      mixpanel.people.set({ "Plan": "Premium" ,"origin":window.location.hostname.split('.')[0], "email": localStorage.getItem('useremail')});
      mixpanel.track('Run Results', {'Test Case ID': params.runId});
      this.pushToParent(this.route, params);
      this.runId = params.runId;
      this.fetchExecutionResult();
      this.getSuitData();
    });
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  getCrossBrowser(){
    return TestPlanType.CROSS_BROWSER
  }
  addeddata(data){
  }

  ngOnDestroy(): void {
    this.removeAutoRefresh();
  }

  togglerunDetails() {
    this.showFilter = false;
    this.showRunDetails = !this.showRunDetails;
  }
private extractTreeData(TestSuite): TreeNode[] {
  const treeData: TreeNode[] = [];
    
  TestSuite.forEach((suite,index) => {
    const treeNode: TreeNode = {
      id: suite.id,
      testCaseId: suite.testCaseId,
      index:index,
      duration : suite.duration,
      name: suite.testSuite.name,
      status:suite.status,
      isManualExecution: suite.isManualExecution,
      result:suite.result,
      testCasesDtoSize: suite.testCasesDtoSize,
      testCasesDto: Array.isArray(suite.testCasesDto) ? suite.testCasesDto : [suite.testCasesDto],
      children: Array.isArray(suite.children) ? suite.children : [suite.children],
    };

    treeData.push(treeNode);
  });
  return treeData;
}

private async addAutoRefresh(listenerChangeTrue?: boolean) {
  if (listenerChangeTrue && this.testPlanResult?.lastRun?.isExecuting && !this.isDisabledAutoRefresh) {
    await this.fetchExecutionResult();
  }
}
drawerOpenClose(node){
  this.drawerOpen = !this.drawerOpen;
  this.selectedCaseId = node.testCaseId;
  this.selectedResultId = node.id;
}

private async getSuitData() {
  this.isExecutionFetchingCompleted = false;
  await this.testPlanResultService.getSuitdata(this.runId).subscribe(async (result: any) => {
    let res = [];
    res = [...result.content];
    this.dataSource.data = res;
    for (const element of res) {
      const tetcaseresult: any = await this.testPlanResultService.gettestCaseData(element.id).toPromise();
      let res2 =[...tetcaseresult.content]
      let newArray = res2.map((data:any, index) => {
        return {
          ...data,
          name: data.testCase.name, // Dynamically setting 'name' property
          index: index // Setting 'index' property to the current index
        };
      });
      element['children'] = newArray;
    }
    const treeData: TreeNode[] = this.extractTreeData(res);
    this.dataSource.data = treeData;
  });
}

durationAsString(ms, maxPrecission = 3) {
  const duration = moment.duration(ms)

  const items = []
  items.push({ timeUnit: 'd', value: Math.floor(duration.asDays()) })
  items.push({ timeUnit: 'h', value: duration.hours() })
  items.push({ timeUnit: 'm', value: duration.minutes() })
  items.push({ timeUnit: 's', value: duration.seconds() })

  const formattedItems = items.reduce((accumulator, { value, timeUnit }) => {
      if (accumulator.length >= maxPrecission || (accumulator.length === 0 && value === 0)) {
          return accumulator
      }

      accumulator.push(`${value}${timeUnit}`)
      return accumulator
  }, [])

  return formattedItems.length !== 0 ? formattedItems.join(' ') : '-'
}
  removeAutoRefresh() {
    if (this.autoRefreshSubscription)
      this.autoRefreshSubscription.unsubscribe();
  }

  toggleAutoRefresh(isDisabledAutoRefresh: boolean) {
    this.isDisabledAutoRefresh = isDisabledAutoRefresh;
    if (this.isDisabledAutoRefresh) {
      this.removeAutoRefresh();
    } else {
      this.addAutoRefresh();
    }
  }

  changeAutoRefreshTime(event: number) {
    this.autoRefreshInterval = event;
    this.addAutoRefresh()
  }

  fetchExecutionResult() {
    this.isExecutionRunning = false;
    this.isExecutionFetchingCompleted = false;
    this.testPlanResultService.show(this.runId).subscribe(result => {
      this.testPlanResult = result;
      this.isExecutionFetchingCompleted = true;
    }, error => {
      this.isExecutionFetchingCompleted = true;
    });
  }

  toggleFilter(showFilter: Boolean) {
    this.showFilter = showFilter
  }

  toggleDetails(showRunDetails: Boolean) {
    this.showRunDetails = showRunDetails
  }

  closeDrawer(event:any){
      this.fetchExecutionResult();
    this.getSuitData();
  }
}
