<div class="page-header d-flex fz-20 align-items-center">
  <div class="ts-col-60 text-truncate rb-medium d-flex align-items-center">
    <span [translate]="'test_suites.list.title'" class="px-8"></span>
  </div>

  <div *ngIf="selectedSuites.length" class="d-flex align-items-center ml-auto">
    <button (click)="openDeleteDialog()" *ngIf="selectedSuites.length"
      [matTooltip]="'hint.message.common.delete_selected' | translate" class="btn icon-btn border-rds-2 ml-14">
      <i class="fa-trash-thin"></i>
    </button>
  </div>

  <div class="align-items-center ml-auto" [class.d-none]="hideHeaderToolBar" [class.d-flex]="!hideHeaderToolBar">
    <!-- <app-toggle-search-button (searchAction)="search($event)"></app-toggle-search-button> -->
    <button [routerLink]="['new']" [translate]="'btn.common.create'" class="theme-btn-primary ml-14 ">
    </button>
    <!-- <app-sort-by-button
      class="pr-15 mt-n2" *ngIf="testSuites?.totalElements"
      [sortByColumns]="sortByColumns"
      [sortedBy]="sortedBy"
      [direction]="direction"
      [translatePreFix]="'test_suites.list.sort_by.'"
      (sortAction)="sortBy($event.sortBy, $event.direction)"></app-sort-by-button> -->
    <!-- <button
        (click)="openFilter()"
        [matTooltip]="'hint.message.common.filter' | translate"
        class="btn icon-btn border-rds-2 filter-icon-with-reset mr-15 ml-14">
        <i class="filter-icon" [class.filtered]="!!this.query"></i>
      </button> -->
    <!-- <div class="pl-15 theme-border-l">
        <app-pagination (paginationAction)="fetchTestSuites()" *ngIf="testSuites?.totalElements"  [currentPage]="currentPage" [paginationData]="testSuites"></app-pagination>
      </div> -->
  </div>
</div>
<div class="page-content ">
  <span class="test-suiteList">
    <app-placeholder-loader *ngIf="!fetchingCompleted"></app-placeholder-loader>
    <div class="list-container" style="overflow-y: auto;" *ngIf="testSuites?.content?.length">
      <div class="list-view green-highlight pointer align-items-cente" style="border: none;">
        <div class="ts-col-30 pt-7 pl-5 pr-5">
          <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding style="padding-left: 0px !important">
              <!-- use a disabled button to provide padding for tree leaf -->
              <p style="font-size: 12px;font-weight: 600;text-decoration: none;color: #616870;"
                (click)="onNodeClick(node)">{{node.name}} </p>
              <button mat-icon-button disabled style="padding-left: 0% !important;"></button>
              <!-- <div style="padding-left: 0% !important;" (click)="onNodeClick(node)"> {{node.name}}</div> -->
              <!-- <p class="ml-auto mr-5">5</p> -->
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding style="border: 1px solid #80808085;margin-bottom: 12px;margin-right: 5px;border-radius: 50px;">
              <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name" style="color: white;background: #6366F1;margin: 3px;">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <div class="ts-col-70" (click)="onNodeClick(node)"
                style="text-decoration: none;color: #4B5563;font-size: 15px;font-weight: 600;padding-left: 15px;">{{node.name }}</div>
              <div class="ts-col-30" [routerLink]="['new', node.id]"
                style="margin-left: 20px !important;font-size: 12px;"> <span class="mr-5"
                  style="font-size: 15px;font-weight: 500;line-height: 14px;">{{node.testCasesDtoSize}}</span> <span
                  class="fa-plus-filled ng-tns-c478-2"></span></div>
            </mat-tree-node>
          </mat-tree>
        </div>
        <div class="ts-col-70" style="border-left: 1px solid #b4b2b2;background: #fafafa;">
          <div class="pl-8">
            <div *ngIf="nodeId">
              <div class="list-view green-highlight pointer align-items-center" style="border: none;">
                <mat-accordion class="ts-col-100">
                  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div class="ts-col-60 my-auto text-truncate">
                          <div class="pb-7 list-title d-flex">
                            <span style="color: black;font-size: 1.1em;"
                              [routerLink]="['/td', 'suites', nestestedDataDisplay.id]" class="pl-15 testsuite-link"
                              title="link">{{nestestedDataDisplay.name}}</span>
                          </div>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="list-container h-90" *ngIf="nestestedDataDisplay.testCasesDto != null">
                      <a class="list-view green-highlight lg-pm pl-10 text-t-secondary align-items-center min-h-20"
                        [routerLink]="['/td', 'cases',  testCases.id]"
                        *ngFor='let testCases of nestestedDataDisplay.testCasesDto'
                        style="background-color: rgb(250 251 252);">
                        <div class="ts-col-60 d-flex align-items-center">
                          <i class="fa-list"></i>
                          <div class="d-flex flex-wrap text-break pl-10 ts-col-100-61">
                            <span class="ts-col-100 pb-0 list-title" [textContent]="testCases?.name"></span>
                          </div>
                        </div>
                        <!-- <div class="ts-col-20">
                              <a (click)="$event.preventDefault();$event.stopPropagation()" [routerLink]="['/td', 'test_case_results', testCases?.lastRun?.id]" *ngIf="testCases?.lastRun">
                                <app-result-pie-chart-column [width]="30" [height]="30" [resultEntity]="testCases?.lastRun"></app-result-pie-chart-column>
                              </a>
                            </div>
                            <div class="ts-col-20 px-25" [textContent]="testCases?.status"></div> -->
                      </a>
                    </div>
                    <div *ngIf="nestestedDataDisplay.testCasesDto == null" class="green-highlight"
                      style="text-align: center;">No Testcase Found</div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
            <div *ngIf="!nodeId" style="
                font-size: 28px;
                color: #6366F1;
                margin-top: 50px;
                font-weight: 500;
                text-align: center;
                align-items: center;">Click on Testsuite</div>
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="!testSuites?.content?.length" class="h-100">
      <div *ngIf="fetchingCompleted" class="empty-full-container">
        <div class="empty-run-md"></div>
        <div [translate]="isFiltered ? 'message.common.search.not_found' : 'test_suites.list.not_created'"
          class="empty-text"></div>
        <button *ngIf="!isFiltered" [routerLink]="['new']" class="theme-btn-primary" target="_parent">
          +
          <span [translate]="'btn.create.test_suite'"></span>
        </button>
      </div>
    </div>
  </span>
</div>