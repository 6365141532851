<form
  class="ts-form p-30 mt-n80"
  [formGroup]="loopForm">
  <div
    *ngIf="testDataList?.content?.length"
    class="align-items-center pb-15 step-container-section pt-4 pl-4"
    [style]="'padding-left: calc('+ indentation*3 +'px + 25px) !important' ">
    <div class="py-10">
      <span
        class="rb-medium"
        [translate]="'test_step.for_loop.profile_title'"></span>
    </div>

    <app-auto-complete
      class="mb-n5"
      [formGroup]="loopForm"
      [formCtrlName]="loopForm.controls['testDataId']"
      [value]="testStep?.testData"
      [items]="testDataList"
      [inline]="true"
      (onSearch)="fetchTestDataProfile(false,$event)"
      (onValueChange)="toggleDataProfile($event)"
    ></app-auto-complete>
    <div class="d-flex pl-15">
      <span
        class="rb-medium py-10"
        [translate]="'test_step.for_loop.start'"></span>
      <div class="pr-15 pt-5" [style.width]="stepRecorderView? '80px':'280px'">
        <mat-form-field
          class="mat-select-custom inline-no-border sm w-100" appearance="fill">
          <mat-select
            disableOptionCentering panelClass="single"
            (selectionChange)="toggleStartIndex()"
            [formControlName]="['startIndex']">
            <mat-option [value]="-1" [textContent]="'test_step.for_loop.option_start' | translate"></mat-option>
            <mat-option
              *ngFor="let startIndex of startArray"
              [value]="startIndex"
              [textContent]="this.testStep.testData.data[startIndex-1]?.name+' ('+startIndex+')'">
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex pl-15">
      <span
        class="rb-medium py-10"
        [translate]="'test_step.for_loop.end'"></span>
      <div class="pl-15 pt-5" [style.width]="stepRecorderView? '80px':'280px'">
        <mat-form-field
          class="mat-select-custom inline-no-border sm w-100" appearance="fill">
          <mat-select
            disableOptionCentering panelClass="single"
            [formControlName]="['endIndex']">
            <mat-option
              *ngFor="let endIndex of endArray"
              [value]="endIndex"
              [textContent]="isNotNumber(endIndex)? endIndex: this.testStep.testData.data[endIndex-1]?.name+' ('+endIndex+')'">
            </mat-option>
            <mat-option [value]="-1" [textContent]="'test_step.for_loop.option_end' | translate"></mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div
    *ngIf="testDataList?.content?.length"
    class="action-cf-btn-container">
    <i class="fa-help text-t-secondary pr-7"></i>
    <a
      class=" text-t-secondary"
      rel="noreferrer nofollow"
      href="https://contextqa.com/docs/test-cases/step-types/for-loop/"
      target="_blank"
      [translate]="'step.create.help.note'"></a>

    <div class="ml-auto">
      <button
        (click)="cancel()"
        class="theme-btn-clear-default"
        [translate]="'btn.common.cancel'"></button>
      <button
        *ngIf="!testStep.id"
        [isLoading]="saving"
        [message]="'message.common.saving'"
        appAsyncBtn
        (click)="save()"
        class="theme-btn-primary"
        [textContent]="'btn.common.create' | translate"></button>
      <button
        *ngIf="testStep.id"
        [isLoading]="saving"
        [message]="'message.common.updating'"
        appAsyncBtn
        (click)="update()"
        class="theme-btn-primary"
        [textContent]="'btn.common.update' | translate"></button>
    </div>
  </div>
  <div
    *ngIf="!testDataList?.content?.length && !isFetching && !searchQuery"
    class="result-status-text-2 py-10  ts-col-100-15">
    <i class="fa-warning px-7"></i>
    <span
      [translate]="'test_step.for_loop.profile.none'"></span>
    <a
      [routerLink]="['/td', version?.id, 'data', 'new']"
      target="_blank"
      class="result-status-text-0 px-2"
      [translate]="'message.common.click_here'"></a>
    <span
      [translate]="'test_step.for_loop.profile.none_mgs'"></span>
  </div>
</form>
