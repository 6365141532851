import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserPreferenceService } from "../services/user-preference.service";
import { AuthenticationGuard } from "../shared/guards/authentication.guard";
import { WorkspaceVersion } from "../models/workspace-version.model";
import { WorkspaceVersionService } from "../shared/services/workspace-version.service";
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from "../shared/components/base.component";
import { OnBoardingSharedService } from "../services/on-boarding-shared.service";
import { MatDialog } from "@angular/material/dialog";
import { TelemetryNotificationComponent } from "./webcomponents/telemetry-notification.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import mixpanel from 'mixpanel-browser';
import { LicenceSevices } from "../shared/services/license.service";
import { CdkConnectedOverlay } from "@angular/cdk/overlay";
import { TestCaseTag } from 'app/models/test-case-tag.model';
import { TestCaseTagService } from 'app/services/test-case-tag.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirstChromeInstallComponent } from './webcomponents/first-chrome-install.component';
declare var chrome: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styles: []
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {
  public version: WorkspaceVersion;
  @ViewChild('shareFeedBackBtn') public shareFeedBackBtn: ElementRef;
  @ViewChild('sortByOptions') overlayDir: CdkConnectedOverlay;
  showTelemetryNotification: boolean;
  Isinstalled: boolean = false;
  lastRun: any;
  lastRunId: string | null = null;
  iframeURL: string;
  iframeURLNew: any;
  licenceDataValue: any;
  IsUpgradeBtn: boolean;
  openFilter: boolean;
  IsManagePlanBtn: boolean;
  filterByValue: string;
  private clickedOnFilterToClose: boolean;
  selectedValue: string = 'LAST_90_DAYS'; // Set 'SUCCESS' as the default value
  baseUrl: string;
  public tags: TestCaseTag[];
  public filterTagIds: number[];
  public createdDateRange = new FormGroup({
    start: new FormControl('', Validators.required),
    end: new FormControl('', Validators.required)
  });
  maxDate = new Date();

  constructor(
    public authGuard: AuthenticationGuard,
    public route: ActivatedRoute,
    private router: Router,
    private versionService: WorkspaceVersionService,
    private userPreferenceService: UserPreferenceService,
    public onBoardingSharedService: OnBoardingSharedService,
    public matModal: MatDialog,
    private sanitizer: DomSanitizer,
    public LicenceSevices: LicenceSevices,
    private testCaseTagService: TestCaseTagService

  ) {
    super(authGuard);
    this.route.queryParams.subscribe(params => {
      this.showTelemetryNotification = params['showTelemetryNotification'];
    });
    this.fetchTags();
    this.checkExtension();
    // this.openFirstInstallChrome();
  }
  checkExtension() {
    if (chrome && chrome.runtime) {
      let data = {
        userEmail: window.location.hostname.split('.')[0]
      };
      chrome.runtime.sendMessage('pgadpooodaipbhbchnojijmlkhpamadh', { message: "getStatus", data: data }, (res: any) => {
        if (res && res.status === "installed") {
          this.Isinstalled = true;
        } else {
          this.Isinstalled = false;
        }
      });
    } else {
      // if chorme extension not install than call this part
    }
  }

  ngOnDestroy() {
    this.onBoardingSharedService.setLastRunId(null);
  }

  ngOnInit(): void {
    this.FetchLicence();
    this.fetchVersion();
    mixpanel.identify(window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail'));  // a - b
    mixpanel.people.set({ "Plan": "Premium", "origin": window.location.hostname.split('.')[0], "email": localStorage.getItem('useremail') });
    mixpanel.track('ContextQA Dashboard');
    if (this.showTelemetryNotification) {
      const dialogRef = this.matModal.open<TelemetryNotificationComponent>(TelemetryNotificationComponent, {
        width: '33%',
        position: { top: '25px', right: '25px' },
        panelClass: ['mat-dialog', 'border-rds-6', 'border-active-t-5']
      });
      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['dashboard']);
      })
    }
    this.onBoardingSharedService.lastRunId$.subscribe((data) => {
      if (data) {
        this.lastRunId = data;
        this.iframeURL = window.location.href;
        const url = new URL(this.iframeURL);
        this.iframeURL = url.origin;
        this.iframeURL = this.iframeURL + "/td/test_case_results/";
        this.iframeURL = this.iframeURL + data;
        this.iframeURLNew = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeURL);
        // Temporarily disable Angular routing
        // Assign the SafeResourceUrl to the iframeURLNew property
        // Re-enable Angular routing after a short delay
        setTimeout(() => {
          window.history.replaceState({}, '', this.router.url);
        }, 2000);
      }
    });

    // this.pushToParent(this.route, this.route.params);
  }

  FetchLicence() {
    this.LicenceSevices.getAll().subscribe(data => {
      this.licenceDataValue = data
      if (this.licenceDataValue.subscribed == false) {
        this.IsUpgradeBtn = true
        this.IsManagePlanBtn = false
      } else {
        this.IsUpgradeBtn = false
        this.IsManagePlanBtn = true
      }
    })
  }
  fetchVersion() {
    this.userPreferenceService.show().subscribe(res => {
      if (res?.versionId) {
        this.versionService.show(res.versionId).subscribe(res => {
          this.version = res;
        }, err => this.loadDemoVersion());
      } else if (res?.projectId) {
        this.versionService.findAll("projectId:" + res.projectId).subscribe(versions => {
          this.version = versions.content[0];
        }, () => {
          this.loadDemoVersion();
        })
      } else {
        this.loadDemoVersion();
      }
    });
  }


  loadDemoVersion() {
    this.versionService.findAll("isDemo:true").subscribe(versions => {
      this.version = versions.content[0];
    })
  }

  get testsigmaOSEnabled() {
    return this.authGuard?.openSource?.isEnabled;
  }

  filter(filterByValue: string) {
    // console.log(filterByValue)
  }

  openFilterByOptions(event: Event) {
    // event.preventDefault();
    // event.stopPropagation();
    if (this.clickedOnFilterToClose) {
      this.clickedOnFilterToClose = false;
      return;
    }
    this.openFilter = true;
    setTimeout(() => {
      this.overlayDir.overlayRef._outsidePointerEvents.subscribe(res => {
        this.overlayDir.overlayRef.detach();
        // if (this.eventFromFilterButton(res))
        //   this.clickedOnFilterToClose = true;
        // else
        //   this.clickedOnFilterToClose = false;
        this.openFilter = false;
      });
    }, 200);
  }

  onSelectChange() {
    if (this.selectedValue != 'CUSTOM') {
      const filterObj = {
        tags: this.filterTagIds,
        createdDateRange: this.createdDateRange,
        customDays: this.selectedValue
      }
      this.LicenceSevices.setDayFilter(filterObj);
    }
  }

  onSelectTag() {
    const filterObj = {
      tags: this.filterTagIds,
      createdDateRange: this.createdDateRange,
      customDays: this.selectedValue
    }
    this.LicenceSevices.setDayFilter(filterObj);
  }

  applyCustomFilter() {
    if (this.createdDateRange.status === 'VALID') {
      const filterObj = {
        tags: this.filterTagIds,
        createdDateRange: this.createdDateRange,
        customDays: this.selectedValue
      }
      this.LicenceSevices.setDayFilter(filterObj);
    }
  }

  clearFilter() {
    this.createdDateRange.reset();
    this.createdDateRange.updateValueAndValidity();
    const filterObj = {
      tags: this.filterTagIds,
      createdDateRange: this.createdDateRange,
      customDays: 'TODAY'
    }
    this.LicenceSevices.setDayFilter(filterObj);
  }

  installPlugin() {
    window.open("https://chromewebstore.google.com/detail/contextqa-test-automation/pgadpooodaipbhbchnojijmlkhpamadh", "_blank");
  }

  private fetchTags(): void {
    this.testCaseTagService.findAll(undefined).subscribe(res => {
      this.tags = res;
    });
  }

  constructQueryString() {

  }

  dateInvalid(DateRange) {
    return ((DateRange.controls.start.value || DateRange.controls.start.errors?.matDatepickerParse?.text) ||
      (DateRange.controls.end.value || DateRange.controls.end.errors?.matDatepickerParse?.text)) &&
      DateRange.invalid;
  }

  openFirstInstallChrome() {
    this.matModal.open(FirstChromeInstallComponent, {
      width: '512px',
      position: { left: '80px', bottom: '60px' },
      panelClass: ['mat-overlay', 'onboarding-help-container'],
      disableClose: true
    });
  }
}
