import { Component } from '@angular/core';
// import { loadStripe, Stripe } from '@stripe/stripe-js';
import { LicenceSevices } from '../../shared/services/license.service'

@Component({
  selector: 'app-stripe-pricing',
//   template: `
//   <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
//   <stripe-pricing-table pricing-table-id="prctbl_1NiHwSKCkF1UmVlR6GAQainY"
//   [publishable-key]="stripePublishableKey">
//   </stripe-pricing-table>`,
  templateUrl: './stripe-pricing.component.html',
  styleUrls: ['./stripe-pricing.component.css']
})

export class StripePricingComponent {
    // public stripePublishableKey: string = 'pk_live_51Mja1ZKCkF1UmVlRBGwOVJ0DtwI4etGqSzWzAJl2W0v7pFtWRxfJ6G3tialjA1SE23gnBcjlDlmBh0iMZ6jXujX400z853LGJd';
    // public strip ="hello world"
    public dataValue: any;
    constructor( public LicenceSevices: LicenceSevices) {}

    ngOnInit(): void {
    }

    OnclickFreeBuy(){
      this.LicenceSevices.getFreePricing().subscribe(data => {
        this.dataValue = data
        window.open(this.dataValue.url, '_blank').focus();
      })
    }

    OnclickBasicBuy(){
      this.LicenceSevices.getBasicPricing().subscribe(data => {
        this.dataValue = data
        window.open(this.dataValue.url, '_blank').focus();
      })
    }

    OnclickPremiumBuy(){
      this.LicenceSevices.getPremiumPricing().subscribe(data => {
        this.dataValue = data
        window.open(this.dataValue.url, '_blank').focus();
      })
    }
}