<form [formGroup]="testPlanForm" class="ts-form ts-col-100 h-100">
  <!-- <div
    [translate]="'test_plans.form.enter_name'"
    class="create-form-title"></div> -->
    <div class="d-flex" style="gap: 8px;" >
  <div class="form-group pb-20 ml-auto mt-5 ts-col-50">
  <div class=""><span style="color: red;">*</span> Enter Test Plan Name</div>

    <input class="form-control mt-2" required name="name" formControlName="name" autofocus />
    <div *ngIf="formSubmitted">
      <div class="error left w-fit-content" style="left: 193px" *ngIf="testPlanForm.controls.name.errors?.required"
        [translate]="'form.validation.common.required' | translate : {FieldName: 'Name'}"></div>
      <div class="error left w-fit-content" style="left: 193px" *ngIf="testPlanForm.controls.name.errors?.minlength"
        [textContent]="'form.validation.common.min_length' | translate: {FieldName:'Name' , min:'4'}"></div>
      <div class="error left w-fit-content" style="left: 193px" *ngIf="testPlanForm.controls.name.errors?.maxlength"
        [textContent]="'form.validation.common.max_length' | translate: {FieldName:'Name' , max:'250'}"></div>
      <div class="error left w-fit-content" style="left: 193px" *ngIf="testPlanForm.controls.name.hasError('whitespace')"
        [textContent]="'form.validation.cannot_have_white_spaces' | translate: {FieldName:'Name'}"></div>
    </div>
  </div>
  <div class="form-group pb-20 p-0 mt-20 ts-col-50">
    <app-tags-chip-list [autofocus]="false" [entityId]="testPlan.id" [service]="tagService"
      (onValueChange)="setTags($event)"></app-tags-chip-list>
    <label class="control-label" [translate]="'testcase.list.tags'"></label>
  </div>

    </div>
<div class="d-flex" style="gap: 8px;">
    <div class="ts-col-50 form-group">
      <span [translate]="'message.common.description_optional'" class="text-dark pointer "></span>
      <div class="mt-4">
        <app-description class="without-label " [formCtrlName]="testPlanForm.controls.description"></app-description>
      </div>
    </div>
<div class="form-group pb-20 ml-auto mt-20 ts-col-50">
  <input class="form-control" type="number" required name="parallelNode" formControlName="parallelNode" autofocus />
  <label class="control-label">Parallel Execution <span style="color: red;">*</span></label>
  <div *ngIf="formSubmitted">
    <div class="error left w-fit-content" style="left: 193px"
      *ngIf="testPlanForm.controls.parallelNode.errors?.required"
      [translate]="'form.validation.common.required' | translate : {FieldName: 'Parallel Node'}"></div>
    <div class="error left w-fit-content" style="left: 193px"
      *ngIf="testPlanForm.controls.parallelNode.errors?.minlength"
      [textContent]="'form.validation.common.min_length' | translate: {FieldName:'Parallel Node' , min:'1'}"></div>
    <div class="error left w-fit-content" style="left: 193px"
      *ngIf="testPlanForm.controls.parallelNode.errors?.maxlength"
      [textContent]="'form.validation.common.max_length' | translate: {FieldName:'Parallel Node' , max:'3'}"></div>
    <div class="error left w-fit-content" style="left: 193px"
      *ngIf="testPlanForm.controls.parallelNode.hasError('whitespace')"
      [textContent]="'form.validation.cannot_have_white_spaces' | translate: {FieldName:'Parallel Node'}"></div>
  </div>
</div>

</div>
 
  <div class="form-group pb-20 ml-auto ts-col-100">
    <mat-slide-toggle formControlName="hideInDashboard"><span [translate]="'Hide on dashboard'"></span>
    </mat-slide-toggle>
  </div>
</form>