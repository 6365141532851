<!-- Start of Subscription Template -->
<subscription-template></subscription-template>
<div class="d-flex flex-column " style="height: 100vh">
  <div class="dashboard-header">
    <div class="header-quick-link-container position-relative">
      <div class="dashboard-global-add" *ngIf="!Isinstalled">
        <div class="btn-group d-flex">
          <div class="wrapper">
            <div class="video-main">
              <div class="promo-video">
                <div class="waves-block">
                  <div class="waves wave-1"></div>
                  <!-- <div class="waves wave-2"></div> -->
                  <!-- <div class="waves wave-3"></div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- install_plugin -->
          <button class="border-rds-left-2 py-7 pl-14 pr-14 btn-primary mr-0 fz-13" (click)="installPlugin()">
            <img style="height: 13px;" src="../../assets/color_icons/cqa-logo.svg"> &nbsp; <span
              [translate]="'Install Plugin'"></span>
          </button>
        </div>
      </div>
      <div class="dashboard-global-add">
        <div class="btn-group d-flex">
          <button class="border-rds-left-2 py-7 pl-14 pr-14 btn-primary mr-0 fz-13">
            <span [translate]="'btn.common.create_new'"></span>
          </button>
          <button class="drop-down-icon-container">
            <i class="fa-down-arrow dr-icon"></i>
          </button>
        </div>
        <app-global-add *ngIf="authGuard?.session?.user" class="gl-drop-menu"></app-global-add>
      </div>

      <div class="dashboard-global-add">
        <div class="btn-group d-flex">
          <button *ngIf="IsUpgradeBtn" class="border-rds-left-2 py-7 pl-14 pr-14 btn-primary mr-0 fz-13"
            [routerLink]="['/pricing']">
            <span [translate]="'btn.common.upgrade'"></span>
          </button>
          <button *ngIf="IsManagePlanBtn" class="border-rds-left-2 py-7 pl-14 pr-14 btn-primary mr-0 fz-13"
            [routerLink]="['/manage-plan']">
            <span [translate]="'btn.common.manage_plan'"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="addon_widget" *ngIf="testsigmaOSEnabled">

    <div class="d-flex-wrap px-20 py-30 ts-col-10 h-100">
      <button [routerLink]="['/addons']" [queryParams]="{create: true}"
        class="border-0 btn-clear btn-clear-default btn-sm pl-14 pointer pr-14 py-7 w-100"
        [translate]="'dashboard.addon.create'"></button>
      <button [routerLink]="['/addons']"
        class="border-0 btn-clear btn-clear-default btn-sm mt-16 pl-14 pointer pr-14 py-7 w-100"
        [translate]="'dashboard.addon.view_more'"></button>
    </div>

  </div>




  <!-- Create box here with background color !-->
  <div class="dashboard-content" *ngIf="version">
    <div class="d-flex flex-wrap ts-col-100">
      <!-- <div class="d-flex flex-wrap ts-col-20" style=" height: 500px; margin-top: 10px">
        <app-test-case-by-count class="ts-col-auto mt-20 pl-20 dashboard-section-xl"
          [version]="version"></app-test-case-by-count>
      </div> -->
      <div class="ts-col-80">
        <div class="ts-col-100 dashboard-heading mb-40">
          <h2>
            Latest Results
          </h2>
          <form class="example-form" style="display: flex;gap: 15px;">
            <div class="form-group">
              <mat-form-field class="sssss" appearance="outline">
                <mat-select (selectionChange)="onSelectTag()" [placeholder]="'message.common.any' | translate"
                  disableOptionCentering panelClass="multiple" multiple [(value)]="filterTagIds">
                  <mat-option *ngFor="let tag of tags" [value]="tag.id" [textContent]="tag.name"></mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group" style="position: relative;" *ngIf="selectedValue == 'CUSTOM'">
              <mat-form-field class="mat-date-custom w-100 sssss dashboard-datepicker" appearance="outline">
                <!-- <mat-label [translate]="'testcase.list.created_date_placeholder'"></mat-label> -->
                <mat-date-range-input [rangePicker]="createdDateRangePicker" [formGroup]="createdDateRange" [max]="maxDate">
                  <input matStartDate formControlName="start" placeholder="Start date" [max]="maxDate" required>
                  <input matEndDate formControlName="end" placeholder="End date" [max]="maxDate" required>
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="createdDateRangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #createdDateRangePicker>
                      <mat-date-range-picker-actions>
                        <button mat-button matDateRangePickerCancel (click)="clearFilter()">Clear</button>
                        <button mat-button matDateRangePickerCancel (click)="selectedValue = 'LAST_90_DAYS';onSelectChange()">Basic</button>
                        <button mat-raised-button color="primary" (click)="applyCustomFilter()" matDateRangePickerApply>Apply</button>
                      </mat-date-range-picker-actions>
                </mat-date-range-picker>
              </mat-form-field>
              <div class="error" *ngIf="dateInvalid(createdDateRange) && !createdDateRangePicker.opened"
                            [translate]="'form.validation.common.invalid' | translate:{FieldName: 'Created Date'}"></div>
            </div>
            <div class="form-group" *ngIf="selectedValue != 'CUSTOM'">
            <mat-form-field class="sssss" appearance="outline">
              <mat-select [(ngModel)]="selectedValue" (ngModelChange)="onSelectChange()">
                <mat-option value="TODAY">Last 24 hr</mat-option>
                <mat-option value="LAST_SEVEN_DAYS">Last 7 Days</mat-option>
                <mat-option value="LAST_30_DAYS">Last 30 Days</mat-option>
                <mat-option value="LAST_90_DAYS">Last 90 Days</mat-option>
                <mat-option value="All_TIME">All Time</mat-option>
                <mat-option value="CUSTOM">Custom Range</mat-option>
              </mat-select>
            </mat-form-field>
            </div>
          </form>
        </div>
        <div class="d-flex flex-wrap ts-col-100 mb-30">
          <app-test-case-coverage-count style="height: 144px;padding-right: 20px;"
            class="dashboard-section-lg ts-col-100  d-flex justify-content-between" [version]="version"
            [selectedValue]="selectedValue">
          </app-test-case-coverage-count>
        </div>
        <div class="d-flex flex-wrap ts-col-100">
          <div class="d-flex flex-wrap ts-col-30">
            <app-test-case-by-count class="dashboard-section-lg ts-col-100  d-flex justify-content-between"
              [version]="version"></app-test-case-by-count>
          </div>
          <div class="d-flex flex-wrap ts-col-70">
            <app-test-case-coverage-summary class="dashboard-section-lg ts-col-100" style="padding-right: 20px;"
              [version]="version"></app-test-case-coverage-summary>
          </div>
        </div>
        <div class="ts-col-100">
          <app-run-result-bar-chart></app-run-result-bar-chart>
        </div>
      </div>
      <div class="d-flex flex-wrap ts-col-20">
        <app-latest-runs class="dashboard-section-lg ts-col-100" [version]="version"
          [lastRunId]="lastRunId"></app-latest-runs>
      </div>
    </div>

    <!-- <div *ngIf="lastRunId" style="margin-top: 50px; width: 100%; height: 100%; overflow: auto !important; border: 2px solid"
         class="d-flex flex-wrap ts-col-100">
         <app-test-case-result-details style="margin-top: -51px;  width: 100%; height: 110%; border: none; overflow: auto !important" width="100%" [is_Model]="true" [is_Dashboard_Model]="true" [added_testCaseResultId]="lastRunId">

         </app-test-case-result-details>
      <iframe *ngIf="iframeURLNew"  style="margin-top: -51px;
  margin-left: -60px; width: 100%; height: 110%; border: none; overflow: auto !important" width="100%"
              [src]="iframeURLNew" ></iframe>
    </div> -->
  </div>

</div>