<div class="d-flex align-items-center theme-gray-light  ts-col-100 pr-15 help-nav"
 style="
    border-bottom: 1px solid #E1E4E8;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: #6366f14d;
">
  <ul class="tab-nav ml-10" role="tablist">
    <li class="nav-items" role="presentation">
      <a (click)="activeTab = 'run_results'" [class.active]="activeTab == 'run_results'" class="normal-text">
        <!-- <i class="fa-step-group"></i> -->
        <span> Run History </span>
      </a>
    </li>
    <!-- <li *ngIf="isActionText" class="nav-items" role="presentation">
      <a (click)="activeTab = 'example'" [class.active]="activeTab == 'example'" class="normal-text">
        <i class="fa-example"></i>
        <span [translate]="'test_step.example.title'"></span>
      </a>
    </li> -->


    <!-- <li class="nav-items" role="presentation">
      <a
        (click)="activeTab = 'help'"
        [class.active]="activeTab == 'help'"
        class="normal-text">
        <i class="fa-help"></i>
        <span class="pl-10" [translate]="'hint.message.common.help'"></span>
      </a>
    </li> -->

    <li class="nav-items" *ngIf="isActionText && cqaOrigin == 'dtest01' || cqaOrigin == 'lululemonand'"
      role="presentation">
      <a (click)="activeTab = 'ai'" [class.active]="activeTab == 'ai'" class="normal-text">
        <!-- <i class="fa-sample"></i> -->
        <span [translate]="'test_step.help.hint.auto_generate'"></span>
      </a>
    </li>
  </ul>
  <button (click)="helpClose()" [matTooltip]="'hint.message.common.close' | translate" class="close"></button>
</div>
<div class="h-100">
  <app-test-step-help-examples *ngIf="activeTab == 'example' && templates && isActionText" [templates]="templates"
    (onSelectTemplate)="SelectTemplate($event)"></app-test-step-help-examples>
  <app-test-step-help-document *ngIf="activeTab == 'help'" [stepType]="stepType" [testcase]="testcase"
    [version]="version"></app-test-step-help-document>

  <app-runs-history *ngIf="activeTab == 'run_results'" class="dashboard-section-lg ts-col-100 h-100" [version]="version"
    [lastRunId]="testcase.id" (nullResult)="handleNullResult()" (fixedData)="fixedData()">
  </app-runs-history>

  <div class="theme-gray-light h-100 action-help" *ngIf="activeTab == 'ai'">
    <div class="px-25 pt-30">
      <div class="details-container">
        <button mat-raised-button color="success" [class.spinner]="IsLoading" [disabled]="IsLoading"
          (click)="generateAICode()">Generate Code</button>
      </div>
      <div *ngIf="javaCode" class="pt-30">
        <div
          style="background-color: #333; color: white; overflow: scroll; width: 100%; height: 370px; align-items: center;">
          <pre class="prettyprint"><code [innerHTML]="javaCode"></code></pre>
        </div>
      </div>
    </div>
  </div>
</div>