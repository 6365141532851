import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { SessionService } from "./session.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  #session!: SessionService;
  constructor(sessionService: SessionService) {
    this.#session = sessionService;
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers: {
      [name: string]: string | string[];
    } = {};
    if (req.body instanceof FormData) {
    } else {
      headers["Content-Type"] = "application/json";
    }

    // headers["cqa-origin"] = "https://dtest01.contextqa.com/api"; //  only use for local

    // headers["cqa-origin"] = "https://dtest01.contextqa.com/api"; //  only use for local

    if (this.#session.apiKey) {
      headers["apiKey"] = this.#session.apiKey;
    }
    const token = this.#session.token;
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    return next
      .handle(
        req.clone({
          url:
            (req.url.startsWith("http") || req.url.startsWith("/api")
              ? ""
              : environment.apiUrl) + req.url,
          setHeaders: headers,
        })
      )
      .pipe(
        tap((err) => {
          if (err instanceof HttpErrorResponse) {
            console.info("Error found");
            if (err.status === 403) {
              this.#session.setToken("");
            }
          }
        })
      );
  }
}
