<div class="page-details-navigation-md min-h-45 pt-16">
  <ul class="tab-nav" role="tablist" *ngIf="!canDrag">
    <li class="nav-items" *ngIf="!headerTabListhidden" role="presentation">
      <a [routerLink]="['/td', 'cases', testCase?.id, 'steps']" [routerLinkActive]="'active'"
        class="normal-text active fs">
        <i class="fa-list"></i>
        <span [textContent]="('result.test_step'  | translate) + (stepLength ?  ' (' + stepLength + ')' : '')"></span>
      </a>
    </li>
    <li *ngIf="testCase?.isStepGroup && !headerTabListhidden" class="nav-items" role="presentation">
      <a [routerLink]="['/td', 'cases', testCase?.id, 'dependents']" [routerLinkActive]="'active'"
        class="normal-text fs">
        <i class="fa-test-cases-alt"></i>
        <span [translate]="'testcase.details.step_group.test_cases'"></span>
      </a>
    </li>
    <li *ngIf="canShowRunResult && !headerTabListhidden" class="nav-items" role="presentation">
      <a [routerLink]="['/td', 'cases', testCase?.id, 'dry_runs']" [routerLinkActive]="'active'" class="normal-text fs">
        <i class="fa-step-group"></i>
        <span [translate]="'testcase.details.dry_runs'"></span>
      </a>
    </li>

    <li class="nav-items" role="presentation">
      <a class="normal-text fs" (click)="refreshData()"><i class="fa-refresh-thick"
          [matTooltip]="'hint.message.common.refresh' | translate"></i> Refresh</a>
    </li>
  </ul>
  <!-- <div style="margin-left: 15px;">
    <a class="normal-text fs" (click)="refreshData()"><i class="fa-refresh-thick"
        [matTooltip]="'hint.message.common.refresh' | translate"></i> Refresh</a>
  </div>
  <div style="margin-left: 15px;">
    <a class="normal-text fs ml-25" (click)="manageVariables()">
      <i class="fa-globe-alt" aria-hidden="true"></i>
      Variables
    </a>
  </div> -->

  <div class="d-flex align-items-center ts-col-15 ml-25" *ngIf="!canDrag && (stepLength > 1 || searchTerm)">
    <!-- <i [matTooltip]="'hint.message.common.search' | translate" class="fa-search fsl mr-5" (click)="focusOnSearch()"></i> -->

    <input class="form-control  w-full d-inline-block   mr-15" (focus)="focusOnSearch()" style="    height: 25px;
    padding: 17px;
    border-radius: 10px;
    border: 1px solid rgb(128 128 128 / 32%);" [(ngModel)]="inputValue" #searchInput
      [placeholder]="'testcase.details.search_action_action' | translate">
    <i [ngClass]="{'visibility-visible' : inputValue}" [matTooltip]="'hint.message.common.clear' | translate"
      class="fa-close-alt text-t-secondary fz-12 pt-2 mr-5 pointer visibility-hidden" (click)="clearSearch()"></i>
  </div>
  <div class="ml-25">
    <div *ngIf="testCaseResult && testCaseResult?.stepStatus === 'FAILURE'" class="notification-icon position-relative">
      <img src="/assets/images/errorSteps.png" width="30" alt="">
      <div class="badge" *ngIf="testCaseResult.result.length > 0">
        <div class="max-w-md mx-auto mt-8">
          <div class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
            <div class="d-flex items-center">
              <svg class="h-6 w-6 mr-2" style="width: 30px;" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
              <p><span class="font-normal"> Step Index:</span> {{ testCaseResult.result[0].failed_step_index }}</p>
            </div>
            <p><span class="font-normal"> Expected Result:</span> {{ testCaseResult.result[0].expected_result }}</p>
            <p><span class="font-normal"> Actual Result:</span> {{ testCaseResult.result[0].actual_result }}</p>
            <p><span class="font-normal"> Reason:</span> {{ testCaseResult.result[0].reason }}</p>
            <div class="mt-2">
              <p class="font-semibold"><span class="font-normal">Suggestions:</span></p>
              <ul class="list-disc list-inside">
                <li *ngFor="let suggestion of testCaseResult.result[0].suggestions.split('\n')">
                  {{ suggestion.trim() }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="testCaseResult?.stepStatus === 'SUCCESS'" [matTooltip]="'Your all steps has been validated'">
      <img src="/assets/images/right.png" width="30" alt="">
    </div>
  </div>
  <span *ngIf="canDrag" [translate]="'testcase.details.steps.re-oder'" class="p-20 fz-14"></span>
  <div class="ml-auto d-flex">
    <button (click)="manageVariables()"
      class="theme-btn-clear-default ml-auto mr-5">
      <i class="fa-globe-alt" aria-hidden="true"></i>
      Variables
    </button>

    <button *ngIf="(!canDrag && !canShowBulkActions && stepLength > 1) || searchTerm"
      (click)="canDrag=true;isReorder=true;" [disabled]="searchTerm" class="theme-btn-clear-default ml-auto">
      <i class="fa-menu-bar text-t-secondary pr-7" aria-hidden="true"></i>
      <span class="text-t-secondary" [translate]="'testcase.details.steps.re-oder'"></span>
    </button>
    <div *ngIf="canDrag">
      <button (click)="cancelDragging();isReorder=false" class="theme-btn-clear-default"
        [translate]="'btn.common.cancel'">
      </button>
      <button [disabled]="!draggedSteps || draggedSteps.length == 0" [isLoading]="saving"
        [message]="'message.common.saving'" appAsyncBtn (click)="updateSorting();isReorder=false"
        class="theme-btn-primary" [textContent]="'btn.common.update' | translate">
      </button>
    </div>

    <div *ngIf="!canDrag">
      <button *ngIf="!isHelpWidgetShowed"  (click)="showHelpWidget()" class="theme-btn-clear-default ml-auto">
        <i class="fa fa-eye text-t-secondary pr-7" aria-hidden="true"></i>
        <span class="text-t-secondary" [translate]="'hint.message.common.quick_view'"></span>
      </button>
    </div>
  </div>
</div>

<div class="d-flex flex-wrap theme-w-o-h-scroll">
  <div [class.ts-col-100]="version?.workspace?.isRest || isRest || isForLoop || canDrag || !isHelpWidgetShowed"
    class="ts-col-70 h-100 overflow-hidden">
    <div [class.showed]="canShowBulkActions" class="bulk-action-container">
      <!-- <button (click)="addIfElseCondition()"
      class="theme-btn-clear-default"
    >
    <span class="result-status-text-0 pr-10 fz-16">+</span>
      <span class="dark-light-text" [translate]="'testcase.details.add_if_else'"></span>
    </button> -->

      <!-- <button (click)="openBulkUpdate()" class="theme-btn-clear-default">
        <i class="fa-bulk-update pr-10 result-status-text-0"></i>
        <span class="dark-light-text" [translate]="'testcase.details.step_bulk_update'"></span>
      </button> -->
      <button (click)="createStepGroupFrom()" class="theme-btn-clear-default">
        <span class="result-status-text-0 pr-10 fz-16">+</span>
        <span class="dark-light-text"
          [translate]="'testcase.details.step_create_group' | translate:{Name: 'Create'}"></span>
      </button>
      <button (click)="bulkDeleteConfirm()" [matTooltip]="'hint.message.common.delete' | translate"
        class="theme-btn-clear-default fa-trash-thin">
      </button>
    </div>
    <app-test-case-action-steps *ngIf="testCase && templates" #childRef
      [ngStyle]="{'height': isRibbonShowed ? 'calc(100% - 38px)': '100%'}" class="d-flex pt-14" [testCase]="testCase"
      [searchTerm]="searchTerm" [version]="version" [templates]="templates" [addonTemplates]="addonAction"
      [selectedTemplate]="selectedTemplate" [isDragEnabled]="canDrag" (onStepsFetch)="setStepLength($event)"
      (onStepSelection)="selectedSteps($event)" (onStepDrag)="onPositionChange($event)"
      (onSelectedStepType)="onStepType($event)"></app-test-case-action-steps>
    <app-placeholder-loader *ngIf="!templates"></app-placeholder-loader>
    <!-- <div
    [class.show]="isRibbonShowed"
    class="step-help-ribbon">
    <div class="d-flex align-items-center pr-20">
      <div
        class="align-items-center d-flex fa-fr-chat justify-content-center p-5 rounded-circle theme-border"></div>
      <span
        class="pl-10"
        [translate]="'step.create.help.expert'"></span>
      <a
        href='https://discord.com/invite/5caWS7R6QX'
        class="text-t-secondary pl-4 text-underline"
        [translate]="'step.create.help.expert_msg'"></a>
    </div>
    <div class="d-flex align-items-center">
      <div
        class="align-items-center d-flex fa-library justify-content-center p-5 rounded-circle theme-border"></div>
      <a
        class="pl-10 pr-20 text-t-secondary"
        rel="noreferrer nofollow"
        [href]="stepArticleUrl"
        target="_blank"
        [translate]="'common.knowledge_base'"></a>
    </div>
    <div class="d-flex align-items-center">
      <div
        class="align-items-center d-flex fa-youtube-thin justify-content-center p-5 rounded-circle theme-border"></div>
      <a
        class="pl-10 pr-20 text-t-secondary"
        [href]="stepVideoUrl"
        target="_blank"
        [translate]="'common.video_tour'"></a>
    </div>
    <i class="fa-times-circle-regular ml-auto fz-16 pointer" (click)="isRibbonShowed= false"></i>
  </div> -->
  </div>
  <div *ngIf="(!version?.workspace?.isRest  || !isRest || !isForLoop || canDrag || !isHelpWidgetShowed ) && !isReorder"
    [class.ts-col-30]="isHelpWidgetShowed" class="h-100">
    <app-test-step-help [class.show]="isHelpWidgetShowed" class="action-help-widget" [templates]="templates"
      [testcase]="testCase" [version]="version" [clickFromHelp]="clickFromHelp" [stepType]="currentStepType"
      (onClose)="isHelpWidgetShowed =false;isReorder=false;" (onSelectTemplate)="onSelectTemplate($event)"
      (reloadStep)="reloadStep()" app-test-step-help *ngIf="testCase && isHelpWidgetShowed">
    </app-test-step-help>
  </div>
</div>