import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TestCaseStatus } from 'app/enums/test-case-status.enum';
import { WorkspaceVersion } from 'app/models/workspace-version.model';
import { TestCaseService } from 'app/services/test-case.service';
import { UserPreferenceService } from 'app/services/user-preference.service';
import { WorkspaceVersionService } from 'app/shared/services/workspace-version.service';

@Component({
  selector: 'app-model-content',
  templateUrl: './model-content.component.html',
  styleUrls: ['./model-content.component.scss']
})
export class ModelContentComponent implements OnInit {

  public versionId: number;
  public version: WorkspaceVersion;
  public urlForm: FormGroup;

  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  dialogReff!: MatDialogRef<any>;
  tabs: chrome.tabs.Tab[] = [];
  private extensionId = 'pgadpooodaipbhbchnojijmlkhpamadh';
  isExtensionInstalled = false;
  createdTestCaseData: any;
  openTabs: string[] = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public userPreferenceService: UserPreferenceService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModelContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public testCaseService: TestCaseService,
    public translate: TranslateService,
    private versionService: WorkspaceVersionService,
  ) {
    this.urlForm = this.formBuilder.group({
      url: ['https://', [Validators.required, this.urlValidator]]
    });

  }

  urlValidator(control: { value: string; }) {
    const url = control.value;
    if (!url) return null;

    const pattern = /^(https?|ftp):\/\/([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[a-zA-Z0-9\-\._~:/?#[\]@!$&'()*+,;=%]*)?$/;
    return pattern.test(url) ? null : { invalidUrl: true };
  }


  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.versionId = params['id'] || 1;
      if (this.versionId > 0) {
        this.fetchVersion();
      }
    });
  }

  fetchVersion() {
    this.versionService.show(this.versionId).subscribe(
      res => this.version = res,
      err => {
        if (err.status == 404) {
          this.versionService.findAll("isDemo:true").subscribe(versions => {
            this.version = versions.content[0];
            window.location.href = "https://accounts.contextqa.com/error?error=Test%20Case%20not%20Exists.";
          });
        }
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.urlForm.valid) {
      let testCase = {
        "isExtensionUsed": false,
        "name": formatDate(new Date(), 'yyyy-MM-dd hh:mm', 'en-US').toString() + "_test_case",
        "description": null,
        "status": TestCaseStatus.READY,
        "sendMailNotification": false,
        "isStepGroup": false,
        "priorityId": 1,
        "type": 1,
        "preRequisite": null,
        "isDataDriven": false,
        "workspaceVersionId": this.version.id,
        "deleted": false,
        "testDataStartIndex": 0,
        "tags": []
      };
      let fieldName = 'Test Case';
      this.testCaseService.autocreate(testCase).subscribe((testcase) => {
        if (chrome && chrome.runtime) {
          let data = {
            type: "test_case",
            id: testcase.id,
            result: testcase,
            action: "openSidePanelFromPortal",
            origin: window.location.hostname.split(".")[0],
            jwt: localStorage.getItem("_t"),
            userEmail: window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail')
          };
          chrome.runtime.sendMessage(
            this.extensionId,
            { message: "openSidePanelFromPortal", data: data },
            (data: any) => {
            }
          );
          this.closeDialog();
          // this.router.navigate(['/dashboard']);
          this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
          const url = this.urlForm.get('url').value;
          window.open(url, '_blank');
        }
      },
        error => {
          // this.saving = false;
          this.translate.get('message.common.created.failure', { FieldName: fieldName }).subscribe((res) => {
            // this.showAPIError(error, res, 'Test Case or Step Group');
          })
        })
    }
  }

}

