import { Component, OnInit } from '@angular/core';
import {ElementFormComponent} from "../webcomponents/element-form.component";
import {WorkspaceVersion} from "../../models/workspace-version.model";
import {ActivatedRoute} from "@angular/router";
import {WorkspaceVersionService} from "../../shared/services/workspace-version.service";
import {MatDialog} from "@angular/material/dialog";
import {ChromeRecorderService} from "../../services/chrome-recoder.service";
import {AgentInfo} from "../../agents/models/agent-info.model";
import {AgentService} from "../../agents/services/agent.service";
import mixpanel from 'mixpanel-browser';


@Component({
  selector: 'app-mobile-inspector',
  templateUrl: './mobile-inspector.component.html',
  styleUrls: ['./mobile-inspector.component.scss']
})
export class MobileInspectorComponent implements OnInit {
  public version: WorkspaceVersion;
  public versionId: number;
  public agentInstalled: Boolean = false;
  constructor(public route: ActivatedRoute,
              private workspaceVersionService: WorkspaceVersionService,
              private matDialog: MatDialog,
              public chromeRecorderService: ChromeRecorderService,
              public agentService: AgentService,) { }

  ngOnInit(): void {
    mixpanel.identify(window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail'));
    mixpanel.people.set({ "Plan": "Premium" ,"origin":window.location.hostname.split('.')[0], "email": localStorage.getItem('useremail')});
    mixpanel.track('Mobile Trace View');
    this.versionId = this.route.parent.snapshot.params.versionId;
    const iframeElement = document.querySelector('iframe');
    iframeElement.removeAttribute('title');
    this.fetchVersion();
  }

  openAddEditElement(elementId) {

    let isNewUI = this.version.workspace.isWeb || this.version.workspace.isMobileWeb ;
    const dialogRef = this.matDialog.open(ElementFormComponent, {
      height: "100vh",
      width: isNewUI?  '540px' : '60%',
      position: {top: '0px', right: '0px'},
      data: {
        isNewUI: isNewUI,
        versionId: this.versionId,
        elementId: elementId
      },
      panelClass: ['mat-dialog', 'rds-none']
    });

  }

  fetchVersion() {
    this.workspaceVersionService.show(this.versionId).subscribe(res => {
      this.version = res;
      if (this.version.workspace.isWebMobile) {
        this.chromeRecorderService.isChromeBrowser();
        //this.fetchChromeExtensionDetails();
        this.chromeRecorderService.pingRecorder();
      } else if (this.version.workspace.isMobileNative) {
        this.pingAgent();
      }
    });
  }

  pingAgent() {
    this.agentService.ping().subscribe((res: AgentInfo) => this.agentInstalled = res.isRegistered);
  }

}
