<div class="strip-pricing ">
    <div class="strip-pricing-title"><span class="dashboard-link" [routerLink]="['/dashboard']">Dashboard >> </span> <span class="active">Pricing</span> </div>
    <div class="table-responsive page-content">
        <table class="table mb-5">
          <thead>
            <tr>
              <th scope="col">GENERAL FEATURES</th>
              <th class="tr-center" scope="col">FREE <span>(1 Week)</span></th>
              <th class="tr-center" scope="col">BASIC</th>
              <th class="tr-center" scope="col">PREMIUM</th>
              <th class="tr-center" scope="col">ELITE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <th></th>
                <td><button class="pricing-btn" (click)="OnclickFreeBuy()">Buy Now</button></td>
                <td><button class="pricing-btn" (click)="OnclickBasicBuy()">Buy Now</button></td>
                <td><button class="pricing-btn" (click)="OnclickPremiumBuy()"> Buy Now</button></td>
                <td><button class="pricing-btn"><a href="mailto:sales@contextqa.co"> Buy Now</a></button></td>
              </tr>
            <tr>
              <th scope="row">No. Of users</th>
              <td>Max 2</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
            </tr>
            <tr>
              <th scope="row">Test Executions</th>
              <td>200 Credits / Month</td>
              <td>10,000 Credits / Month</td>
              <td>200,000 Credits / Month</td>
              <td>Customize</td>
            </tr>
            <tr>
              <th scope="row">Projects & Applications</th>
              <td>1 Per Month</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
            </tr>
            <tr>
                <th scope="row">Test Case Management</th>
                <td>20 Test Case Creation/Month</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <th scope="row">Cross-Browser Testing: Web</th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256" style="fill:#000000;">
                        <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                       </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256" style="fill:#000000;">
                        <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                       </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Cross-Browser Testing: Mobile</th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256" style="fill:#000000;">
                        <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                       </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256" style="fill:#000000;">
                        <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                       </svg>
                </td>
              </tr>
            <tr>
              <th scope="row">Data Storage & Retention</th>
              <td>2 GB for One Month</td>
              <td>100 GB for 3 Months</td>
              <td>200 GB for 6 Months</td>
              <td>Customize</td>
            </tr>
            <tr>
                <th scope="row">Videos & Screenshots of Tests</th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256" style="fill:#000000;">
                     <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256" style="fill:#000000;">
                     <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256" style="fill:#000000;">
                     <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256" style="fill:#000000;">
                     <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
            </tr>
            <tr>
                <th scope="row">Smart Recorder</th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256" style="fill:#000000;">
                     <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Web Testing</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Mobile Testing</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr> <tr>
                <th scope="row">Service Virtualisation</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr> 
              <tr>
                <th scope="row">ERP Testing</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg></td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr> 
              <tr>
                <th scope="row">API Testing</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr> 
              <tr>
                <th scope="row">Accessibility Testing</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg></td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr> 
              <tr>
                <th scope="row">Load or Performance Testing</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg></td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr> 
              <tr>
                <th scope="row">Cross-Device Testing</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg></td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr> 

              <!-- AI-RELATED FEATURES START -->
              <tr>
                <th scope="row" style="background-color: #6366F1; color: #fff;" colspan="5">AI-RELATED FEATURES</th>
              </tr>
              <tr>
                <th scope="row">Root-cause Analysis</th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Visual Regression</th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Auto Healing</th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Test Case Migration</th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <!-- AI-RELATED FEATURES END -->

              <!-- INTEGRATIONS START -->
              <tr>
                <th scope="row" style="background-color: #6366F1; color: #fff;" colspan="5">INTEGRATIONS</th>
              </tr>
              <tr>
                <th scope="row">AML/Defect Management Tool (Proprietry Jira Management Tool)</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">DevOps CI|CD <br>
                    (Eg. Jenkins, Git and Bamboo)</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <!-- INTEGRATIONS END -->

              <tr>
                <th scope="row" style="background-color: #6366F1; color: #fff;" colspan="5">TEST OPS</th>
              </tr>
              <tr>
                <th scope="row">Visual Test Design</th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>

              <tr>
                <th scope="row">Test Suites</th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>

              <tr>
                <th scope="row">Test Steps</th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>

              <tr>
                <th scope="row">Parallel Execution</th>
                <td>1</td>
                <td>1</td>
                <td>2</td>
                <td>Customize</td>
              </tr>
              <tr>
                <th scope="row">Reusability Libraries  </th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Managerial Reports </th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Managerial Dashboard </th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Test Run History </th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row" style="background-color: #6366F1; color: #fff;" colspan="5">ENTERPRISE SECURITY</th>
              </tr>
              <tr>
                <th scope="row">SSO</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">VPN Access</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Whitelisting/Static Grid IPs</th>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                    style="fill:#000000;">
                    <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M21,24.15l-12.143,12.143l-4.15,-4.15l12.143,-12.143l-12.143,-12.143l4.15,-4.15l12.143,12.143l12.143,-12.143l4.15,4.15l-12.143,12.143l12.143,12.143l-4.15,4.15z" fill="#fa5252"></path><path d="M33.143,4.414l3.443,3.443l-11.436,11.436l-0.707,0.707l0.707,0.707l11.436,11.436l-3.443,3.443l-11.436,-11.436l-0.707,-0.707l-0.707,0.707l-11.436,11.436l-3.443,-3.443l11.436,-11.436l0.707,-0.707l-0.707,-0.707l-11.436,-11.436l3.443,-3.443l11.436,11.436l0.707,0.707l0.707,-0.707l11.436,-11.436M33.143,3l-12.143,12.143l-12.143,-12.143l-4.857,4.857l12.143,12.143l-12.143,12.143l4.857,4.857l12.143,-12.143l12.143,12.143l4.857,-4.857l-12.143,-12.143l12.143,-12.143l-4.857,-4.857z" fill="#4788c7"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Data Encryption </th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Test Result Retention </th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
              <tr>
                <th scope="row">Test History </th>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
                <td>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10" height="10" viewBox="0,0,256,256"
                        style="fill:#000000;">
                    <g fill="#0fd343" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M1.707,22.199l2.779,-2.779l8.876,8.877l22.152,-22.152l2.779,2.779l-24.931,24.931z"></path><path d="M35.514,6.852l2.072,2.072l-24.223,24.224l-10.949,-10.949l2.072,-2.072l8.169,8.169l0.707,0.707l0.707,-0.707l21.445,-21.444M35.514,5.438l-22.151,22.152l-8.876,-8.876l-3.487,3.485l12.363,12.363l25.637,-25.638l-3.486,-3.486z"></path></g></g>
                    </svg>
                </td>
              </tr>
          </tbody>
        </table>
    </div>
</div>