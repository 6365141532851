import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { TestPlanResultService } from "../../services/test-plan-result.service";
import { InfiniteScrollableDataSource } from "../../data-sources/infinite-scrollable-data-source";
import { FilterTimePeriod } from "../../enums/filter-time-period.enum";
import * as moment from 'moment';
import { Page } from "../../shared/models/page";
import { TestDeviceResult } from "../../models/test-device-result.model";
import { TestPlanResult } from "../../models/test-plan-result.model";
import { TestDeviceResultService } from "../../shared/services/test-device-result.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TestCaseResultService } from "../../services/test-case-result.service";
import { TestCaseResult } from "../../models/test-case-result.model";
import { OnBoardingSharedService } from "../../services/on-boarding-shared.service";
import { UserPreferenceService } from "../../services/user-preference.service";
import { LicenceSevices } from "../../shared/services/license.service";
import { interval, of, Subscription } from "rxjs";
import { TestCaseService } from "../../services/test-case.service";
import { TestCase } from "../../models/test-case.model";
import { TestStepService } from "../../services/test-step.service";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RunHistoryDetailsComponent } from '../results/run-history-details/run-history-details.component';
import { TestStepResultService } from 'app/services/test-step-result.service';
import { DomSanitizer } from '@angular/platform-browser';
import { switchMap } from 'rxjs/operators';
import { errorFixTypepopupComponent } from './errorFix-Type-popup.component';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { BaseComponent } from 'app/shared/components/base.component';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationGuard } from 'app/shared/guards/authentication.guard';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-runs-history',
  templateUrl: './runs-history.component.html',
  styles: []
})
export class RunsHistoryComponent extends BaseComponent implements OnInit {
  @Input('version') version: WorkspaceVersion;
  @Output() nullResult = new EventEmitter<void>();
  @Output() fixedData = new EventEmitter<void>();
  private hasEmittedNullResult: boolean = false; // Add a flag
  @Input() lastRun: any;
  @Input() lastRunId: any;
  public period: FilterTimePeriod = FilterTimePeriod.LAST_90_DAYS;
  public dryTestCaseResults: InfiniteScrollableDataSource;
  public testPlanResults: InfiniteScrollableDataSource;
  public dayResults: InfiniteScrollableDataSource;
  public weekResults: InfiniteScrollableDataSource;
  public monthResults: InfiniteScrollableDataSource;
  public quarterResults: InfiniteScrollableDataSource;
  public filterTimePeriod = FilterTimePeriod;
  public environmentResults: Page<TestDeviceResult>;
  public activeExecutionResult: TestPlanResult;
  public isRunning: boolean;
  public autoRefreshSubscription: Subscription;
  public isDisabledAutoRefresh: boolean = false;
  public autoRefreshInterval: number = 10000;
  public testCaseId: number;
  public testCase: TestCase;
  noSteps: any;
  items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);
  private runhistoryModal !: any;
  test_steps_executed!: any[];
  testStepDataRunResult: any;
  traceViewer: any;

  constructor(
    public matModal: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
    private environmentResultService: TestDeviceResultService,
    private testPlanResultService: TestPlanResultService,
    private testCaseResultService: TestCaseResultService,
    private userPreferenceService: UserPreferenceService,
    public LicenceSevices: LicenceSevices,
    private testStepService: TestStepService,
    private testCaseService: TestCaseService,
    private onBoardingSharedService: OnBoardingSharedService,
    private dialog: MatDialog,
    private testStepResultService: TestStepResultService,
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    private sanitizer: DomSanitizer) {
    super(authGuard, notificationsService, translate, toastrService)

  }

  async ngOnInit(): Promise<any> {
    this.testCaseId = this.lastRunId || this.route.parent.snapshot.params.testCaseId;
    this.fetchTestCaseResults();
    this.fetchTestCase();
    this.attachAutoRefreshEvents();
    this.fetchTestSteps();
  }

  fetchTestSteps() {
    let query = "testCaseId:" + this.testCaseId;
    this.testStepService.findAll(query).subscribe(res => {
      this.noSteps = res.empty;
      this.testCaseService.emitStepLength(res?.content?.length);
    });
  }

  fetchTestCase() {
    this.testCaseService.show(this.testCaseId).subscribe(res => {
      this.testCase = res;
    })
  }

  attachAutoRefreshEvents() {
    document.addEventListener("visibilitychange", () => {
      document.hidden ? this.removeAutoRefresh() : this.addAutoRefresh(true);
    });
  }

  fetchTestCaseResults() {
    this.isRunning = false;
    this.removeAutoRefresh();
    this.dryTestCaseResults = new InfiniteScrollableDataSource(this.testCaseResultService, "entityType:TEST_PLAN,iteration:null,testCaseId:" + this.testCaseId, "id,desc");
    this.testCaseResultService.findAll("entityType:TEST_PLAN,iteration:null,testCaseId:" + this.testCaseId, "id,desc").subscribe(res => {
      if (res.content.length === 0 && !this.hasEmittedNullResult) {
        this.nullResult.emit();
        this.hasEmittedNullResult = true; // Set the flag to true
        return;
      }
      res.content.filter(dryexecution => {
        if (dryexecution && dryexecution.isQueued) {
          this.isRunning = true;
          this.addAutoRefresh();
          return;
        }
      })
    })
  }

  addAutoRefresh(listenerChangeTrue?: boolean) {
    if (listenerChangeTrue && this.isRunning && !this.isDisabledAutoRefresh) {
      this.fetchTestCaseResults();
    }
    this.removeAutoRefresh();
    if (!this.isRunning || this.isDisabledAutoRefresh)
      return;
    this.autoRefreshSubscription = interval(this.autoRefreshInterval).subscribe(() => {
      this.fetchTestCaseResults();
    });
  }


  removeAutoRefresh() {
    if (this.autoRefreshSubscription) {
      this.isRunning = false;
      this.autoRefreshSubscription.unsubscribe();
    }
  }


  filter(period: FilterTimePeriod) {
    this.period = period;
    const date = this.getFormattedDate(period);
    this.userPreferenceService.setDaysFilter({ period: this.period, date: date })
    switch (this.period) {
      case FilterTimePeriod.TODAY:
        this.testPlanResults = this.dayResults;
        break;
      case FilterTimePeriod.LAST_SEVEN_DAYS:
        this.testPlanResults = this.weekResults;
        break;
      case FilterTimePeriod.LAST_30_DAYS:
        this.testPlanResults = this.monthResults;
        break;
      case FilterTimePeriod.LAST_90_DAYS:
        this.testPlanResults = this.quarterResults;
        break;
    }
    this.setFirstActiveResult();
  }

  private getFormattedDate(key: FilterTimePeriod): String {
    switch (key) {
      case FilterTimePeriod.TODAY:
        return moment().format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_SEVEN_DAYS:
        return moment().subtract(7, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_30_DAYS:
        return moment().subtract(30, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_90_DAYS:
        return moment().subtract(90, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_180_DAYS:
        return moment().subtract(180, 'd').format("YYYY-MM-DD");
    }
  }

  private setFirstActiveResult(): void {
    if (this.testPlanResults.isFetching)
      setTimeout(() => this.setFirstActiveResult(), 500);
    else
      if (this.testPlanResults.isEmpty) this.environmentResults = null;
    this.activeExecutionResult = <TestPlanResult>this.testPlanResults['cachedItems'][0];
    if (this.activeExecutionResult)
      this.fetchEnvironmentResults();
  }

  private fetchEnvironmentResults(): void {
    this.environmentResults = null;
    this.environmentResultService.findAll("testPlanResultId:" + this.activeExecutionResult.id).subscribe(res => {
      this.environmentResults = res;
    });
    this.testCaseResultService.findAll(`iteration:null,testPlanResultId:${this.activeExecutionResult.id}`).subscribe((res: Page<TestCaseResult>) => {
      this.onBoardingSharedService.setLastRunId(res.content[0].id);
    });

  }

  public setActiveResult(result: TestPlanResult) {
    this.activeExecutionResult = result;
    this.fetchEnvironmentResults();
    this.testCaseResultService.findAll(`iteration:null,testPlanResultId:${this.activeExecutionResult.id}`).subscribe((res: Page<TestCaseResult>) => {
      this.onBoardingSharedService.setLastRunId(res.content[0].id);
    });
    // this.router.navigate(['/td','runs',result.id])
  }
  async openQuickFix(testStepData) {
    const stepId = testStepData.type === 'TS_TIMEOUT' ? testStepData.timeoutStep : testStepData.failed_step_id;
    // First, retrieve the matching step data
    const dialogRef = await this.testStepService.show(stepId).pipe(
      switchMap(matchingStep => {
        // Once the matching step is retrieved, open the modal dialog with the necessary data
        return of(this.matModal.open(errorFixTypepopupComponent, {
          height: '30%',
          width: '30%',
          data: { testStepData, matchingStepData: matchingStep },
          panelClass: ['mat-dialog', 'rds-none']
        }).afterClosed());
      })
    ).toPromise();

    // Handle dialog close response
    dialogRef.subscribe((data) => {
      if (data && data != "Cancel") {
        this.showNotification(NotificationType.Success, 'Value updated successfully');
        this.fixedData.emit();
      }
    });
  }
  /** Run History Detail Modal */
  runResultsHistory(data: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/run-history-view'], {
        queryParams: {
          testCaseId: this.testCaseId,
          runResultId: data.id,
          isManualExecution: data.isManualExecution
        }
      })
    );
    window.open(url, '_blank');

    // this.openModalInstant();
    // this.fetchTestCaseResult(data.id);
    // let query;
    // query = query ? query + "," : "";
    // query += "groupResultId:null,testCaseResultId:" + data.id;
    // this.testStepResultService.findAll(query, data.isManualExecution ? "id,asc" : "id,asc").subscribe(res => {
    //   if (res.content.length) {
    //     let testStepIds = res.content.map((data: any) => data.stepId);
    //     this.fetchTestStepsData(testStepIds, res.pageable, res.content);
    //   } else {
    //     this.test_steps_executed = [];
    //   }
    // });
  }

  // fetchTestStepsData(testStepIds, pageable, teststepResult) {
  //   let queryString = "id@" + testStepIds.join("#")
  //   this.testStepService.findAll(queryString, 'position', pageable).subscribe(res => {
  //     this.test_steps_executed = res.content.map((data: any) => {
  //       return {
  //         ...data,
  //         teststepResult: teststepResult.find((res) => res.stepId == data.id)
  //       };
  //     });
  //     this.runhistoryModal.componentInstance.TestSteps = this.test_steps_executed;
  //     this.runhistoryModal.componentInstance.testCaseData = this.testCase;
  //     this.runhistoryModal.componentInstance.testStepDataRunResult = this.testStepDataRunResult;
  //     this.runhistoryModal.componentInstance.traceViewer = this.traceViewer;
  //     this.runhistoryModal.componentInstance.loader = false;
  //   });
  // }


  /** Open Modal When clock on Run */
  // openModalInstant() {
  //   let config = new MatDialogConfig();
  //   config = {
  //     width: '100vw',
  //     maxWidth: '100%',
  //     height: '100%',
  //     panelClass: 'full-screen-modal',
  //     hasBackdrop: false
  //   };
  //   this.runhistoryModal = this.dialog.open(RunHistoryDetailsComponent, config);
  //   this.runhistoryModal.componentInstance.loader = true;
  // }

  // fetchTestCaseResult(id: any) {
  //   this.testCaseResultService.show(id).subscribe(res => {
  //     this.testStepDataRunResult = res;
  //     if (this.testStepDataRunResult?.traceViewers && this.testStepDataRunResult?.traceViewers != null) {
  //       this.traceViewer = this.testStepDataRunResult?.traceViewers.map(item => item ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://trace.playwright.dev/?trace=${item}`) : null);
  //     }
  //   });
  // }
}
