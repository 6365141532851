import { ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TestCase } from 'app/models/test-case.model';
import { TestStepService } from 'app/services/test-step.service';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-execution-model',
  templateUrl: './execution-model.component.html',
  styleUrls: ['./execution-model.component.scss']
})
export class ExecutionModelComponent implements OnInit, DoCheck {
  @ViewChild('logContainer') private logContainer: ElementRef;

  public Prop: any;
  public loader: any;
  public TestCase: any;
  public TestSteps: TestCase[];
  public ExecutedStepsData: TestCase[];
  public time: any;
  public failSteps: any;
  public testRunId: any;
  public successSteps: any;
  public stepsData: any = [];
  public isFull_Model: boolean = false;
  public activeLog: string = 'log';
  stepGroupSteps: any = [];
  public displayedLogs: { text: string, complete: boolean }[] = [];
  private lastLogLength: number = 0;
  public Conversation: any;
  public message: any;


  constructor(
    private dialogRef: MatDialogRef<ExecutionModelComponent>,
    private testStepService: TestStepService,
    private route: Router,
    private socket: Socket,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnChanges(data: SimpleChange) {
    console.log(this.Prop)
  }

  ngOnInit(): void {
  }

  ngDoCheck() {
    if (this.Prop && this.Prop.log && this.Prop.log.length > this.lastLogLength) {
      const newLogs = this.Prop.log.slice(this.lastLogLength);
      newLogs.forEach(log => this.addNewLog(log));
      this.lastLogLength = this.Prop.log.length;
    }
  }

  private addNewLog(log: string): void {
    this.displayedLogs.push({ text: '', complete: false });
    this.simulateTyping(log, this.displayedLogs.length - 1);
  }

  private simulateTyping(log: string, index: number): void {
    let charIndex = 0;
    const interval = setInterval(() => {
      if (charIndex < log.length) {
        this.displayedLogs[index].text += log[charIndex];
        charIndex++;
        this.cdr.detectChanges();
        this.scrollToBottom();
      } else {
        clearInterval(interval);
        this.displayedLogs[index].complete = true;
        this.cdr.detectChanges();
      }
    }, 50); // Adjust typing speed as needed
  }

  private scrollToBottom(): void {
    try {
      this.logContainer.nativeElement.scrollTop = this.logContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }


  /** Re Run */
  reRun(value?: boolean): void {
    this.dialogRef.close({ data: 'you confirmed' });
    this.testStepService.onRerun.emit()
  }

  /** Close Dialog */
  closeDialog() {
    this.dialogRef.close({ data: 'you confirmed' });
  }

  fullModel() {
    this.isFull_Model = !this.isFull_Model;
  }

  /** Get Step Data */
  expandedIndex: number | null = null;
  getStepDetailData(data: any, index: number) {
    if (this.expandedIndex === index) {
      this.expandedIndex = null;
    } else {
      this.expandedIndex = index;
    }
    if (data.stepGroupId) {
      let query = "testCaseId:" + data.stepGroupId;
      this.testStepService.findAll(query).subscribe((res) => {
        this.stepGroupSteps = res;
      });
    }
  }

  /** Re direct to test run  */
  redirectToRunDetail() {
    const url = this.route.createUrlTree(['/td/runs/', this.testRunId]).toString();
    window.open(url, '_blank');
  }

  sentevent(){
    this.socket.emit('internal', {message: this.message , topic: this.Conversation?.replyBack_id });
    this.Conversation = null;
  }
}
