import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NaturalTextActions } from "../../models/natural-text-actions.model";
import { Page } from "../../shared/models/page";
import { TestCase } from "../../models/test-case.model";
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { TestStepType } from "../../enums/test-step-type.enum";
import { NaturalTextActionsService } from "../../services/natural-text-actions.service";
import Prism from 'prismjs';
import mixpanel from 'mixpanel-browser';

@Component({
  selector: 'app-test-step-help',
  templateUrl: './test-step-help.component.html',
  styles: [`
    .log-item {
      border: 5px solid rgb(210, 210, 210);
      padding: 10px;
      margin-bottom: 10px;
      word-wrap: break-word;

    }
    .err{
      border-color: #f4c4c4;
    }
    .success{
      border-color: green;
    }
    .warning{
      border-color: yellow;
    }
    .default{
      border-color: grey;
    }
    @keyframes spinner {
      to {transform: rotate(360deg);}
    }

    .mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary{
      background-color: blue !important;
    }

    .spinner:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
      border-radius: 50%;
      border: 2px solid #ffffff;
      border-top-color: #000000;
      animation: spinner .8s linear infinite;
    }
  `]
})
export class TestStepHelpComponent implements OnInit {
  @Input('templates') templates: Page<NaturalTextActions>;
  @Input('testcase') testcase: TestCase;
  @Input('version') version: WorkspaceVersion;
  @Input('stepType') stepType: string;
  @Input('clickFromHelp') clickFromHelp: boolean;
  @Output('onClose') onClose = new EventEmitter<void>();
  @Output('reloadStep') reloadStep = new EventEmitter<void>();
  @Output('onSelectTemplate') onSelectTemplate = new EventEmitter<NaturalTextActions>();
  public activeTab: string;
  javaCode: any = '';
  public IsLoading: boolean = false;
  cqaOrigin: any;
  constructor(private naturalTextActionsService: NaturalTextActionsService) {
  }

  ngOnInit(): void {
    this.cqaOrigin = window.location.hostname.split('.')[0];
  }



  ngOnChanges() {
    this.activeTab = !this.isActionText ? 'help' : 'run_results';
  }

  get isActionText() {
    return this.stepType == TestStepType.ACTION_TEXT;
  }

  helpClose() {
    this.onClose.emit();
  }

  generateAICode() {
    try {
      this.IsLoading = true;
      mixpanel.identify(window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail'));
      mixpanel.people.set({ "Plan": "Premium", "origin": window.location.hostname.split('.')[0], "email": localStorage.getItem('useremail') });
      mixpanel.track('Generate AI Code');
      this.naturalTextActionsService.generateAI(this.testcase.id).subscribe((res) => {
        this.IsLoading = false;
        const grammar = Prism.languages['java'];
        this.javaCode = Prism.highlight(res.code, Prism.languages.javascript, 'javascript');
      })
    } catch (e) {
      this.IsLoading = false;
    }

  }

  SelectTemplate(template: NaturalTextActions) {
    this.onSelectTemplate.emit(template)
  }
  fixedData(): void {
    this.reloadStep.emit();
  }
  handleNullResult(): void {
    if (!this.clickFromHelp) {
      this.onClose.emit();
    }
  }

}
