/*
 *
 *  * *****************************************************************************
 *  *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  *  All rights reserved.
 *  *  ****************************************************************************
 *
 */

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { TestStepResult } from "../../models/test-step-result.model";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { VisualTestingComponent } from "./visual-testing.component";
import { Page } from "../../shared/models/page";
import { TestCaseResult } from "../../models/test-case-result.model";
import { TestStepListItemComponent } from "./test-step-list-item.component";
import { AuthenticationGuard } from "../../shared/guards/authentication.guard";
import { NotificationsService } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { TestStepService } from "../../services/test-step.service";
import { TestStep } from "../../models/test-step.model";
import { NaturalTextActions } from "../../models/natural-text-actions.model";
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { AddonNaturalTextAction } from "../../models/addon-natural-text-action.model";
import { NaturalTextActionsService } from "../../services/natural-text-actions.service";
import { RestService } from "app/shared/services/rest/rest.service";

@Component({
  selector: "app-test-step-result-list-item",
 
  templateUrl:'test-step-result-list-item.component.html',
  styles: [],
})
export class TestStepResultListItemComponent
  extends TestStepListItemComponent
  implements OnInit
{
  @Input("testStepResult") testStepResult: TestStepResult;
  @Input("activeStepGroup") activeStepGroup: TestStepResult;
  @Input("stepNumber") stepNumber: any;
  @Input("filteredTestStepResult") filteredTestStepResult: Page<TestStepResult>;
  @Input("isStepGroupChild") isStepGroupChild: Boolean = false;
  @Input("testcaseResult") testcaseResult: TestCaseResult;
  @Input("templates") templates: Page<NaturalTextActions>;
  @Input("addonTemplates") addonTemplates?: Page<AddonNaturalTextAction>;
  @Input("testSteps") testSteps: Page<TestStep>;
  @Input("workspaceVersion") workspaceVersion: WorkspaceVersion;
  @Input("isEditEnabled") isEditEnabled?: boolean = false;
  @Input("isDryRun") isDryRun: boolean;
  @Output("activeStepGroupAction") activeStepGroupAction =
    new EventEmitter<TestStepResult>();
  @Output("stepUpdateAction") stepUpdateAction = new EventEmitter<String>();
  @Output("onStepEditAction") onStepEditAction = new EventEmitter<any>();
  @Output("onStepDetails") onStepDetails = new EventEmitter<void>();
  @Output("onActiveStepGroup") onActiveStepGroup = new EventEmitter<void>();
  @Output("onFirstFailedStep") onFirstFailedStep =
    new EventEmitter<TestStepResult>();
    @Input() is_Model:boolean = false;
  public isExpandStepGroup: boolean = false;

  constructor(
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    public naturalTestActionService: NaturalTextActionsService,
    private matDialog: MatDialog,
    public router: Router,
    public testStepService: TestStepService,
    restService: RestService
  ) {
    super(
      authGuard,
      notificationsService,
      translate,
      toastrService,
      testStepService,
      naturalTestActionService,
      matDialog,
      restService
    );
  }

  ngOnInit() {
    if (this.testStepResult?.testStep) {
      this.testStepResult.testStep["stepGroup"] =
        this.testStepResult?.stepGroup;
      if (this.addonTemplates?.totalElements) {
        this.testStepResult.testStep.addonTemplate =
          this.addonTemplates.content.find(
            (template) =>
              template.id == this.testStepResult.testStep.addonActionId
          );
      }
    }
  }

  ngOnChanges() {
    if (this.testStepResult?.testStep) {
      this.testStepResult.testStep["stepGroup"] =
        this.testStepResult?.stepGroup;
      if (this.addonTemplates?.totalElements) {
        this.testStepResult.testStep.addonTemplate =
          this.addonTemplates.content.find(
            (template) =>
              template.id == this.testStepResult.testStep.addonActionId
          );
      }
    }
  }

  fetchStepGroupResults(testStepResult: TestStepResult) {
    if (this.activeStepGroup?.id == testStepResult.id)
      this.onActiveStepGroup.emit();
    else {
      this.activeStepGroupAction.emit(testStepResult);
    }
    if (this.isExpandStepGroup) {
      setTimeout(() => (this.isExpandStepGroup = false), 150);
    }
  }

  openViComparison(testStepResult) {
    this.matDialog.open(VisualTestingComponent, {
      width: "100vw",
      height: "100vh",
      position: { top: "0", left: "0", right: "0", bottom: "0" },
      data: {
        screenshotComparisonId:
          testStepResult?.stepResultScreenshotComparison?.id,
        filteredTestStepResult: this.filteredTestStepResult,
      },
      panelClass: ["mat-dialog", "full-width", "rds-none"],
    });
  }

  //TODO: [Pratheepv] Bad need to check why cdk virtual scroll and [routerActiveLink] not working together
  isStepActive(testStepResult: TestStepResult) {
    if(!this.is_Model)
    return this.router.url.indexOf("/step_results/" + testStepResult.id) > 0;
  }

  deleteStepByResult(testStepResult: TestStepResult) {
    this.filteredTestStepResult.content.filter(
      (stepResult) => (stepResult.isDelete = false)
    );
    super.deleteStep(testStepResult.testStep);
    testStepResult.isDelete = true;
  }

  editStepByResult(testStepResult: TestStepResult) {
    this.filteredTestStepResult.content.filter(
      (stepResult) => (stepResult.isEditing = false)
    );
    testStepResult.isEditing = true;
    testStepResult.testStep ? this.onStepEditAction.emit(true) : "";
    // this.onStepEditAction.emit({value: true, step: event});
  }

  onSave(event, testStepResult: TestStepResult) {
    testStepResult.isEditing = false;
    super.onStepSave(event);
    this.onStepEditAction.emit(false);
    // this.onStepEditAction.emit({value: false, step: event});
  }

  onCancel(event, testStepResult: TestStepResult) {
    testStepResult.isEditing = false;
    super.onFormCancel(event);
    this.onStepEditAction.emit(false);
    // this.onStepEditAction.emit({value: false, step: event});
  }

  onTriggerDetails() {
    this.onStepDetails.emit();
  }

  gotoStepGroup($event: MouseEvent) {
    this.isExpandStepGroup = true;
    // $event.preventDefault();
    // $event.stopPropagation();
    // $event.stopImmediatePropagation();
  }

  unExecutedCondition(testStepResult: TestStepResult) {
    return (
      ((testStepResult.parentResult?.stepDetails?.isConditionalIf ||
        testStepResult.parentResult?.stepDetails?.isConditionalElseIf ||
        testStepResult.parentResult?.stepDetails?.isConditionalElse) &&
        testStepResult.isNotExecuted) ||
      testStepResult.isNotExecuted
    );
  }

  stepGroupStepNumber(
    childStepNumber: number,
    testStepResult: TestStepResult
  ): any {
    return testStepResult.stepDisplayNumber + "." + (childStepNumber + 1);
  }

  setIfFailedStep(childStepResult) {
    childStepResult.isFailed
      ? this.onFirstFailedStep.emit(childStepResult)
      : "";
  }
}
