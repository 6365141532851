import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSelectChange } from '@angular/material/select';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenShortOverlayComponent } from 'app/components/webcomponents/screen-short-overlay.component';
import { TestCase } from 'app/models/test-case.model';
import { TestCaseResultService } from 'app/services/test-case-result.service';
import { TestCaseService } from 'app/services/test-case.service';
import { TestStepResultService } from 'app/services/test-step-result.service';
import { TestStepService } from 'app/services/test-step.service';
import { Page } from 'app/shared/models/page';
import { ReportModalComponent } from './report-modal/report-modal.component';
import { UrlConstantsService } from 'app/shared/services/url.constants.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { AuthenticationGuard } from 'app/shared/guards/authentication.guard';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'app/shared/components/base.component';
import { errorFixTypepopupComponent } from 'app/components/webcomponents/errorFix-Type-popup.component';
import { catchError, switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { VideoAnalysisService } from './video-analysis.service';

@Component({
  selector: 'app-run-result-page',
  templateUrl: './run-result-page.component.html',
  styleUrls: ['./run-result-page.component.scss']
})
export class RunResultPageComponent extends BaseComponent implements OnInit {
  testCaseId: any;
  runResultId: any;
  isManualExecution: any;
  test_steps_executed!: any[];
  public testCaseData: TestCase;
  testStepDataRunResult: any;
  traceViewer: any;
  loader: boolean = false;
  public testCases: Page<TestCase>;
  currentActivetab: string = "video";
  currentActivetabLight: string = "ai";
  public stepsActivedOption: string = "console";
  @ViewChild(MatAccordion) accordion: MatAccordion;
  public isScreenshotBroken: boolean = false;
  stepGroupSteps: any = [];
  selectedStepData: any;
  htmlContent: SafeHtml | null = null;
  private reportModalDialog!: any;
  cqaOrigin: any;

  constructor(
    private route: ActivatedRoute,
    private testStepService: TestStepService,
    private testStepResultService: TestStepResultService,
    private testCaseService: TestCaseService,
    private sanitizer: DomSanitizer,
    private testCaseResultService: TestCaseResultService,
    public router: Router,
    public matModal: MatDialog,
    private http: HttpClient,
    private dialog: MatDialog,
    private URLConstants: UrlConstantsService,
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    private videoAnalysisService: VideoAnalysisService
  ) {
    super(authGuard, notificationsService, translate, toastrService)
  }

  ngOnInit(): void {
    this.cqaOrigin = window.location.hostname.split('.')[0];
    // this.loader = true;
    this.route.queryParams.subscribe(async params => {
      this.testCaseId = params['testCaseId'];
      this.runResultId = params['runResultId'];
      this.isManualExecution = params['isManualExecution'];
      await this.fetchTestCaseResult();
      await this.fetchTestCase();
      await this.testStepResult();
    });
    // this.loader = false;
  }

  /** Fetch test Step */
  fetchTestStepsData(testStepIds, pageable, teststepResult) {
    let queryString = "id@" + testStepIds.join("#")
    this.testStepService.findAll(queryString, 'position', pageable).subscribe(res => {
      this.test_steps_executed = res.content.map((data: any) => {
        return {
          ...data,
          teststepResult: teststepResult.find((res) => res.stepId == data.id)
        };
      });
    });
  }

  /** Fetch test cases */
  fetchTestCase() {
    this.testCaseService.show(this.testCaseId).subscribe(res => {
      this.testCaseData = res;
    });
  }

  /** Fetch Test Step Result */
  testStepResult() {
    let query;
    query = query ? query + "," : "";
    query += "groupResultId:null,testCaseResultId:" + this.runResultId;
    this.testStepResultService.findAll(query, this.isManualExecution ? "id,asc" : "id,asc").subscribe(res => {
      if (res.content.length) {
        let testStepIds = res.content.map((data: any) => data.stepId);
        this.fetchTestStepsData(testStepIds, res.pageable, res.content);
      } else {
        this.test_steps_executed = [];
      }
    });
  }

  /** Fetch Test Case Result Data */
  fetchTestCaseResult() {
    this.testCaseResultService.show(this.runResultId).subscribe(res => {
      this.testStepDataRunResult = res;
      // console.log("this.testStepDataRunResult>>>>>>>>>", this.testStepDataRunResult);
      if (this.testStepDataRunResult?.traceViewers && this.testStepDataRunResult?.traceViewers != null) {
        this.traceViewer = this.testStepDataRunResult?.traceViewers.map(item => item ? this.sanitizer.bypassSecurityTrustResourceUrl(`https://trace.playwright.dev/?trace=${item}`) : null);
      }
    });
  }

  /**Fetch Impact DataTestCases */
  fetchImapctDataTestCases() {
    this.testCaseService
      .findAllImpactAnalysis(this.testCaseData.lastRun.id as number)
      .subscribe((res) => {
        this.testCases = res;
      });
  }

  /** Open NewTab OpenAI */
  openInNewTab(event: MouseEvent, testCaseResultId: string) {
    event.preventDefault();
    event.stopPropagation();
    const url = this.router
      .createUrlTree(["/td", "cases", testCaseResultId])
      .toString();
    window.open(url, "_blank");
  }

  /** Open Screen Show */
  openScreenShort() {
    this.matModal.open(ScreenShortOverlayComponent, {
      width: "100vw",
      height: "100vh",
      position: { top: "0", left: "0", right: "0", bottom: "0" },
      data: {
        screenShortUrl:
          this.testStepDataRunResult?.executedResult?.failed_test_steps[0]
            ?.stepScreenshotUrl,
      },
      panelClass: ["mat-dialog", "full-width", "rds-none"],
    });
  }

  async openQuickFix() {
    let testStepData = this.testStepDataRunResult?.executedResult?.failed_test_steps[0];
    const stepId = testStepData.type === 'TS_TIMEOUT' ? testStepData.timeoutStep : testStepData.failed_step_id;

    // First, retrieve the matching step data
    const dialogRef = await this.testStepService.show(stepId).pipe(
      switchMap(matchingStep => {
        // Once the matching step is retrieved, open the modal dialog with the necessary data
        return of(this.matModal.open(errorFixTypepopupComponent, {
          height: '30%',
          width: '30%',
          data: { testStepData, matchingStepData: matchingStep },
          panelClass: ['mat-dialog', 'rds-none']
        }).afterClosed());
      })
    ).toPromise();

    // Handle dialog close response
    dialogRef.subscribe((data) => {
      if (data && data != "Cancel") {
        this.showNotification(NotificationType.Success, 'Value updated successfully');
      }
    });
  }

  /** Get Step Data */
  expandedIndex: number | null = null;
  getStepDetailData(data: any, index: number) {
    if (this.expandedIndex === index) {
      this.expandedIndex = null;
    } else {
      this.expandedIndex = index;
    }
    this.testStepResultService.show(data.teststepResult.id).subscribe((res) => {
      this.selectedStepData = res;
      this.currentActivetabLight = "visual-regression";
      this.stepsActivedOption = "console";
    });

    if (data.stepGroupId) {
      let query = "testCaseId:" + data.stepGroupId;
      this.testStepService.findAll(query).subscribe((res) => {
        this.stepGroupSteps = res;
      });
    }
  }

  /** Set Broken Image */
  setBrokenImage() {
    this.isScreenshotBroken = true;
  }

  fetchHtmlContent(url: string): void {
    // this.http.get(url, { responseType: 'text' }).subscribe(
    //   data => {
    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(url);
    //   },
    //   error => {
    //     console.error('Error fetching HTML content', error);
    //   }
    // );
  }

  onReportChange(event: MatSelectChange): void {
    const report = event.value;
    this.fetchHtmlContent(report); // Assuming each report has a 'url' property

    let config = new MatDialogConfig();
    config = {
      width: "100vw",
      maxWidth: "100%",
      height: "100%",
      panelClass: "full-screen-modal",
      hasBackdrop: false,
    };
    this.reportModalDialog = this.dialog.open(ReportModalComponent, config);
    this.reportModalDialog.componentInstance.innerHTML = this.htmlContent;
  }

  /** Get Summary */
  getSummary() {
    this.loader = true;
    if (!this.testStepDataRunResult?.screenRecordings) {
      this.showNotification(NotificationType.Error, 'No video recording found for the summary.');
      this.loader = false;
      return;
    }

    const videoUrl = this.testStepDataRunResult.screenRecordings[0];

    this.videoAnalysisService.analyzeVideo(videoUrl).subscribe({
      next: (res: any) => {
        console.log('API response received:', res);
        if (res.content) {
          this.updateTestCase(res.content);
        } else {
          console.warn('Response received, but no content found');
          this.showNotification(NotificationType.Error, 'No content in the response');
        }
      },
      error: (err: any) => {
        console.error('Error in component:', err);
        this.showNotification(NotificationType.Error, err.message);
      },
      complete: () => {
        console.log('API request completed');
        this.loader = false;
      }
    });
  }

  /** Update Test Case */
  updateTestCase(content) {
    const payload = {
      id: this.testCaseId,
      description: this.formatContentAsHtml(content),
      status: this.testCaseData.status
    }
    this.http.put(this.URLConstants.testCasesUrl + "/" + this.testCaseId, payload).subscribe({
      next: (res: any) => {
        if (res) {
          this.showNotification(NotificationType.Success, 'Successfully done');
        }
        this.loader = false;
      },
      error: (err: any) => {
        this.loader = false;
      }
    });
  }

  /** Update Test Case */
  updateTestCaseResult(content) {
    this.updateAllCapabilities(this.testStepDataRunResult);
    this.testStepDataRunResult.message = content;
    this.http.put(this.URLConstants.testCaseResultsUrl + "/update/" + this.runResultId, this.testStepDataRunResult).subscribe({
      next: (res: any) => {
        if (res) {
          this.showNotification(NotificationType.Success, 'Successfully done');
        }
        this.loader = false;
      },
      error: (err: any) => {
        this.loader = false;
      }
    });
  }

  updateAllCapabilities(obj: any) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === 'capabilities' && Array.isArray(obj[key])) {
          obj[key] = "[]";
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          this.updateAllCapabilities(obj[key]);
        }
      }
    }
  }

  /** Analize */
  rootAnalize: any;
  analyze() {
    this.loader = true;

    // Arrays to hold the success and failure steps
    let successSteps: string[] = [];
    let failedSteps: string[] = [];

    // Process each step in test_steps_executed
    this.test_steps_executed.forEach((step: any, index: number) => {
      const stepNumber = index + 1;
      const stepDescription = step.action; // Adjust according to your actual object structure
      const formattedStep = `${stepNumber}\n${stepDescription}`;
      if (step.teststepResult?.result === 'SUCCESS') {
        successSteps.push(formattedStep);
      } else if (step.teststepResult?.result === 'FAILURE') {
        failedSteps.push(formattedStep);
      }
    });

    // Join the steps into single strings
    const successStepsString = successSteps.join('\n');
    const failedStepsString = failedSteps.join('\n');

    // Create the JSON payload
    const payload = {
      url: this.testStepDataRunResult?.screenRecordings[0],
      success_steps: successStepsString,
      failed_steps: failedStepsString,
      task: 'analysis'
    };

    this.videoAnalysisService.reviewRoot(payload).subscribe({
      next: (res: any) => {
        console.log('API response received:', res);
        if (res.content) {
          this.rootAnalize = this.formatContentAsHtml(res.content);
          this.updateTestCaseResult(this.rootAnalize);
        } else {
          console.warn('Response received, but no content found');
          this.showNotification(NotificationType.Error, 'No content in the response');
        }
      },
      error: (err: any) => {
        console.error('Error in component:', err);
        this.showNotification(NotificationType.Error, err.message);
      },
      complete: () => {
        console.log('API request completed');
        this.loader = false;
      }
    });

    // // Make the HTTP POST request
    // this.http.post("https://web-finder.contextqa.com/video/test", payload).subscribe({
    //   next: (res: any) => {
    //     if (res.content) {
    //       this.rootAnalize = this.formatContentAsHtml(res.content);
    //       this.updateTestCaseResult(this.rootAnalize);
    //     }
    //     this.loader = false;
    //   },
    //   error: (err: any) => {
    //     console.error('API Error:', err);
    //     this.showNotification(NotificationType.Error, 'Something went wrong');
    //     this.loader = false;
    //   }
    // });
  }

  formatContentAsHtml(content: string): string {
    // Format the string with HTML tags
    return content
      .replace(/1\./g, '<ol><li>')
      .replace(/2\./g, '</li><li>')
      .replace(/3\./g, '</li><li>')
      .replace(/4\./g, '</li><li>')
      .replace(/5\./g, '</li><li>')
      .replace(/###/g, '</li></ol><h3>')
      .replace(/- /g, '<li>')
      .replace(/:/g, ':</li>')
      .replace(/\n/g, '<br>');
  }

  downloadAndViewReport(report: any) {
    window.open(report, '_blank');
  }


}
