<!-- <ng-template #dialogTemplate> -->
    <div mat-dialog-content class="test-case-dialog">
        <div class="dialog-header">
            <button mat-icon-button class="close-button" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="dialog-content">
            <a href="javascript:void(0)" class="primary-icon-plus">
                <div><i class="fa-plus-filled"></i></div>
            </a>
            <h2>Create Test Case</h2>
            <p style="font-size: 18px !important;">Select or open a new tab to start capturing steps automatically</p>
            <form [formGroup]="urlForm" (ngSubmit)="onSubmit()">
                <div appearance="fill" class="url-input">
                    <input matInput placeholder="Enter website name" formControlName="url" class="form-control"
                        >
                    <mat-error *ngIf="urlForm.get('url')?.hasError('required')">URL is required</mat-error>
                    <mat-error *ngIf="urlForm.get('url')?.hasError('invalidUrl')">Please enter a valid https://
                        URL</mat-error>
                </div>
                <div class="test-list-container">
                    <ul class="test-list">
                        <li *ngFor="let tab of tabs" class="test-item">
                            <img [src]="tab.favIconUrl || ''" alt="Tab icon" class="tab-icon">
                            <span class="tab-title">{{ tab.title }}</span>
                            <span class="tab-url">{{ tab.url }}</span>
                        </li>
                    </ul>
                </div>
                <div class="btn">
                    <button type="submit" class="create-test" [disabled]="!urlForm.valid">Create Test</button>
                </div>
                <div class="text-center sm mt-20" style="color: #5a67d8;cursor: pointer;" [routerLink]="['/td', versionId, 'cases', 'create']" (click)="closeDialog()">
                    Manually create  a test step
                </div>
            </form>
        </div>
    </div>
<!-- </ng-template> -->