import { Component, Inject, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TestDevelopmentComponent } from 'app/components/test-development.component';
import { WorkspaceVersion } from 'app/models/workspace-version.model';
import { UserPreferenceService } from 'app/services/user-preference.service';
import { WorkspaceVersionService } from 'app/shared/services/workspace-version.service';
import { TestCaseService } from 'app/services/test-case.service';
import { formatDate } from '@angular/common';
import { TestCaseStatus } from 'app/enums/test-case-status.enum';

@Component({
  selector: 'app-test-case-library',
  templateUrl: './test-case-library.component.html',
  styleUrls: ['./test-case-library.component.scss']
})
export class TestCaseLibraryComponent implements OnInit {
  public versionId: number;
  public version: WorkspaceVersion;
  public urlForm: FormGroup;

  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  dialogReff!: MatDialogRef<any>;
  tabs: chrome.tabs.Tab[] = [];
  private extensionId = 'pgadpooodaipbhbchnojijmlkhpamadh';
  isExtensionInstalled = false;
  createdTestCaseData: any;
  openTabs: string[] = [];

  constructor(
    private dialog: MatDialog,
    public route: ActivatedRoute,
    public router: Router,
    private versionService: WorkspaceVersionService,
    public userPreferenceService: UserPreferenceService,
    private formBuilder: FormBuilder,
    private testCaseService: TestCaseService,
    @Optional() private dialogRef?: MatDialogRef<TestDevelopmentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: { versionId: number }
  ) {
    this.urlForm = this.formBuilder.group({
      url: ['https://', [Validators.required, this.urlValidator]]
    });
  }

  urlValidator(control: { value: string; }) {
    const url = control.value;
    if (!url) return null;

    const pattern = /^https:\/\/([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return pattern.test(url) ? null : { invalidUrl: true };
  }

  openDialog() {
    this.dialogReff = this.dialog.open(this.dialogTemplate, {
      width: '500px',
    });
  }

  ngOnInit(): void {
    this.getTabs();
    this.checkExtension();
    this.route.params.subscribe((params) => {
      this.versionId = params['id'] || 1;
      if (this.versionId > 0) {
        this.fetchVersion();
        this.createTestCase();
      }
    });
  }

  async checkExtension() {
    this.isExtensionInstalled = await this.userPreferenceService.isExtensionInstalled(this.extensionId);
  }

  fetchVersion() {
    this.versionService.show(this.versionId).subscribe(
      res => this.version = res,
      err => {
        if (err.status == 404) {
          this.versionService.findAll("isDemo:true").subscribe(versions => {
            this.version = versions.content[0];
            window.location.href = "https://accounts.contextqa.com/error?error=Test%20Case%20not%20Exists.";
          });
        }
      }
    );
  }

  closeDialog() {
    if (this.dialogReff) {
      this.dialogReff.close();
    }
  }

  get detailsAliasName() {
    const leftPath = this.router.url.split("/")[3];
    return isNaN(parseInt(leftPath)) ? leftPath : this.router.url.split("/")[2];
  }

  async getTabs(): Promise<void> { }

  onSubmit() {
    if (this.urlForm.valid) {
      if (chrome && chrome.runtime) {
        let data = {
          type: "test_case",
          id: this.createdTestCaseData.id,
          result: this.createdTestCaseData,
          action: "openSidePanelFromPortal",
          origin: window.location.hostname.split(".")[0],
          jwt: localStorage.getItem("_t"),
          userEmail: window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail')
        };
        chrome.runtime.sendMessage(
          this.extensionId,
          { message: "openSidePanelFromPortal", data: data },
          (data: any) => {
          }
        );
        this.closeDialog();
        this.router.navigate(['/dashboard']);
        const url = this.urlForm.get('url').value;
        window.open(url, '_blank');
      }
    }
  }

  onUrlInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    let url = inputElement.value;
    if (!url.startsWith('https://')) {
      url = 'https://' + url.replace('https://', '');
      this.urlForm.patchValue({ url: url }, { emitEvent: false });
      inputElement.setSelectionRange(8, 8);
    }
  }

  createTestCase() {
    let testCase = {
      "isExtensionUsed": false,
      "name": formatDate(new Date(), 'yyyy-MM-dd hh:mm', 'en-US').toString() + "_test_case",
      "description": null,
      "status": TestCaseStatus.READY,
      "sendMailNotification": false,
      "isStepGroup": false,
      "priorityId": 1,
      "type": 1,
      "preRequisite": null,
      "isDataDriven": false,
      "workspaceVersionId": this.versionId,
      "deleted": false,
      "testDataStartIndex": 0,
      "tags": []
    };
    this.testCaseService.autocreate(testCase).subscribe((testcase) => { this.createdTestCaseData = testcase; })
  }
}
